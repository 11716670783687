import { all, takeEvery, put, call } from "redux-saga/effects";
import {
  SET_CHAT_HISTORY,
  CLEAR_CHAT_HISTORY_WATCHER,
  SET_RELEVANT_FILES_WATCHER,
} from "../types";
import { clearChatHistory, setRelevantFiles } from "../action";
import ClearHistoryService from "../../configs/services/ClearHistoryService";
import { IRelevantFiles } from "../../configs/type/types";

let timer: any;
const delay = (time: number) =>
  new Promise((resolve) => {
    timer = setTimeout(resolve, time);
  });
function* UpdateClearIntevals(): Generator<any, any, any> {
  yield call(clearTimeout, timer);
  yield call(delay, 60 * 60000);
  yield call(ClearHistoryService);
  yield call(clearHistory);
}
function* clearHistory(): Generator<any, any, any> {
  yield call(ClearHistoryService);
  yield put(clearChatHistory());
}
function* setRelevantFileswatcher(action: {
  type: string;
  payload: Partial<IRelevantFiles>[];
}) {
  yield put(setRelevantFiles(action.payload));
}

function* watchDataClearInterval() {
  yield takeEvery(SET_CHAT_HISTORY, UpdateClearIntevals);
}
function* watchDataClearApi() {
  yield takeEvery(CLEAR_CHAT_HISTORY_WATCHER, clearHistory);
}
function* watchRelevantTableUpdate() {
  yield takeEvery(SET_RELEVANT_FILES_WATCHER, setRelevantFileswatcher);
}

export function* rootSagas() {
  yield all([
    watchDataClearInterval(),
    watchDataClearApi(),
    watchRelevantTableUpdate(),
  ]);
}
