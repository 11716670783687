import "./InputBox.scss";
import SendIcon from "../../assets/send.svg";
import LinkButton from "../LinkButton/LinkButton";
import LoadingIndicator from "../LoadingIndicator";
import { State } from "../../redux/reducer";
import { useSelector } from "react-redux";
import { charLimit } from "../../utils";
type InputBoxProps = {
  queryText: string;
  selectedSegment?: boolean;
  refInput: React.RefObject<HTMLTextAreaElement>;
  hasQueries: boolean;
  handleOnChange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSendMessage: (ev: React.MouseEvent<HTMLElement>) => void;
  handleResetMessage: () => void;
  handleKeyDown: (ev: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
  isValidQuery: boolean;
};

const InputBox = ({
  selectedSegment,
  queryText = "",
  refInput,
  hasQueries,
  disabled,
  isValidQuery,
  handleOnChange,
  handleSendMessage,
  handleResetMessage,
  handleKeyDown,
}: InputBoxProps) => {
  const { isClearLoading, downloadFileMessage, errorMessage } = useSelector(
    (state: State) => state
  );
  const INPUT_PLACEHOLDER = "Ask me a question";
  const TEXT_HEIGHT = queryText.length > 150 ? 60 : 20;
  const labelClass = selectedSegment ? "chatbox__label" : "chatbox__label-dark";

  return (
    <div className={"chatbox"}>
      <div
        className={
          hasQueries
            ? "chatbox__text-area-container-wchat"
            : "chatbox__text-area-container"
        }
      >
        <textarea
          ref={refInput}
          className={"chatbox__text-area"}
          value={queryText}
          onChange={handleOnChange}
          rows={1}
          placeholder={INPUT_PLACEHOLDER}
          maxLength={charLimit}
          style={{
            height: TEXT_HEIGHT,
            cursor: disabled ? "not-allowed" : "default",
          }}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <div onClick={handleSendMessage} className="chatbox__send-icon">
          <img
            style={{
              cursor: disabled || !isValidQuery ? "not-allowed" : "default",
            }}
            src={SendIcon}
            alt={"send"}
          />
        </div>
      </div>
      <div
        className={
          hasQueries ? "chatbox__char-limit-wchat" : "chatbox__char-limit"
        }
      >
        <span>
          {" "}
          ({queryText.length}/{charLimit})
        </span>
      </div>
      {hasQueries && (
        <>
          <div className="chatbox__clearbtn-box">
            {!isClearLoading && (
              <LinkButton
                onClick={handleResetMessage}
                disabled={disabled || isClearLoading}
              >
                Clear chat
              </LinkButton>
            )}
            {isClearLoading && <LoadingIndicator color="#FFF" />}
            <span className="chatbox__clearbtn-box">
              {(errorMessage || downloadFileMessage) && (
                <p className="chatbox__download-message">
                  {errorMessage || downloadFileMessage}{" "}
                  <LoadingIndicator
                    color="#FFF"
                    parentWidth="4px"
                    zeroPercentheight="4px"
                    fifltyPercentheight="4px"
                    thirdDotSpace={"19px"}
                    secDotSpace={"13px"}
                  />
                </p>
              )}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
export default InputBox;
