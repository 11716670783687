import React, { useRef } from "react";
import TranscriptsInsight from "../transcripts-insight";
import CustomHeader from "../../component/CustomHeader";
import { RootStateOrAny, useSelector } from "react-redux";
import InsightHeader from "../../component/CustomHeader/InsightHeader";
import ExampleQueries from "../../component/example-queries/ExampleQueries";
import Footer from "../../component/common/footer/Footer";
import "./TranscriptsHome.scss";

const TranscriptsHome = () => {
  const dropdownRef = useRef(null);
  const { chatDataSummmary } = useSelector(
    (state: RootStateOrAny) => state.openAi
  );

  const handleNavigationHome = () => {
    // window.location.replace(`${process.env.REACT_APP_HOME_ROUTE}`);
  };
  const { length } = chatDataSummmary.table;

  return (
    <React.Fragment>
      <div className={"unstructure"} ref={dropdownRef}>
        <CustomHeader handleNavigationHome={handleNavigationHome} />
        <InsightHeader length={length} />
        <div className={length ? "unstructure__scrollable-content" : ""}>
          <div
            className={
              length > 0
                ? "unstructure__container-with-chat"
                : "unstructure__container"
            }
          >
            <div
              className={
                length > 0
                  ? "unstructure__shadow-container"
                  : "unstructure__shadow-container-wc"
              }
            >
              <TranscriptsInsight />
            </div>
            <div
              className={
                length > 0 ? "unstructure__no-example" : "unstructure__example"
              }
            >
              <ExampleQueries />
            </div>
          </div>
        </div>
        <Footer length={length} />
      </div>
    </React.Fragment>
  );
};
export default TranscriptsHome;
