import pptxgen from "pptxgenjs";
import { sanitizeHeaderText, downloadFilenameGenerator } from ".";
import {
  IQueryContent,
  RESPONSE_BY,
  IRelevantFiles,
} from "../configs/type/types";

export type pptDataType = {
  query: string;
  response: IQueryContent | null;
};

export const exportChatToPPT = ({ query, response }: pptDataType) => {
  try {
    let presentation = new pptxgen();
    let slide = presentation.addSlide();
    let rows: pptxgen.TableRow[] = [];
    rows.push([{ text: query, options: { bold: true } }]);
    if (response) {
      let { relevantFiles, summary } = response;
      rows.push([{ text: summary, options: { margin: 10 } }]);
      slide.addTable(rows, {
        autoPage: true,
      });

      if (
        relevantFiles &&
        relevantFiles.length > 0 &&
        relevantFiles.every((el) => Boolean(el) && typeof el === "object")
      ) {
        const documentTableRows: pptxgen.TableRow[] = [];
        const docuementTableHead: pptxgen.TableCell[] = [
          {
            text: sanitizeHeaderText("Relevant Document(s)"),
            options: { bold: true },
          },
          {
            text: sanitizeHeaderText("Author(s)"),
            options: { bold: true },
          },
        ];

        documentTableRows.push(docuementTableHead);
        relevantFiles.forEach((el: Partial<IRelevantFiles>) => {
          const rowValues: pptxgen.TableCell[] = [];
          const filesName = el.file_name;
          let filesPage =
            el?.page_number && el.page_number.length > 1
              ? "(Slides "
              : "(Slide ";

          el.page_number?.forEach((slide: string, i) => {
            if (el?.page_number && el.page_number.length > i + 1) {
              filesPage = filesPage + slide + ", ";
            } else {
              filesPage = filesPage + slide + ")";
            }
          });
          let authorData: string = "";
          if (el.authors) {
            authorData = el.authors.reduce((str, auth) => {
              if (str === "") {
                str = auth?.name;
              } else {
                str = str + ", " + auth?.name;
              }
              return str;
            }, "");
          }

          rowValues.push({
            text: filesName + "\n" + filesPage,
          });

          rowValues.push({
            text: authorData,
          });
          documentTableRows.push(rowValues);
        });
        presentation.addSlide().addTable(documentTableRows, {
          border: { color: "e8e8ee", pt: 1, type: "solid" },
          fill: {
            color: "f7f7f8",
          },
          autoPage: true,
          autoPageCharWeight: -0.5,
          autoPageLineWeight: 0.5,
        });
      }
    }

    presentation
      .write({ outputType: "blob" })
      .then((file: any) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file as Blob);
        link.download = downloadFilenameGenerator(
          RESPONSE_BY.STRATEGY_EDGE,
          "ppt"
        );
        link.click();
      })
      .catch((err: any) => console.log("::PPT catch errr", err));
  } catch (err) {
    console.log("::::PPT error", err);
  }
};
