import { IQuery, IQueryContent, RESPONSE_BY } from "../../configs/type/types";
import { GenrateRandomAuthor } from "../../utils";
import {
  CLEAR_CHAT_HISTORY,
  GET_CHAT_HISTORY,
  SET_CHAT_HISTORY,
  SET_DOWNLOAD_FILE_MESSAGE,
  SET_ERROR_MESSAGE,
  TOGGLE_GPT_SIDEBAR,
  TOGGLE_LOADER,
  TOOBLE_CLEAR_LOADER,
  SET_CHAT_SUMMARY,
  SET_CLEAR_SUMMARY,
  SET_WHOLE_CHAT_TABLE,
  SET_CHUNK_DATA_LOADING,
  SET_RELEVANT_FILES,
} from "../types";

export type State = {
  chatData: IQuery[];
  isLoading: boolean;
  isClearLoading: boolean;
  downloadFileMessage?: string;
  errorMessage?: string;
  chatDataSummmary: any;
  chunkLoading: boolean;
};

export const initialState: State = {
  chatData: [],
  isLoading: false,
  isClearLoading: false,
  downloadFileMessage: "",
  errorMessage: "",
  chatDataSummmary: {
    table: [],
  },
  chunkLoading: false,
};

export type chatDataSummmaryTypes = {
  table: IQuery[];
};
export type IChat = {
  chatData: IQuery[];
  isLoading: boolean;
  isClearLoading: boolean;
  downloadFileMessage?: string;
  errorMessage?: string;
  chatDataSummmary: chatDataSummmaryTypes;
  chunkLoading: boolean;
};

export const chatReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CHAT_HISTORY: {
      return {
        ...state,
      };
    }

    case SET_CHAT_HISTORY: {
      if (
        payload[0][RESPONSE_BY.OPENAI] ||
        payload[0][RESPONSE_BY.STRATEGY_EDGE]
      ) {
        if (payload[0][RESPONSE_BY.STRATEGY_EDGE]?.Content) {
          payload[0][RESPONSE_BY.STRATEGY_EDGE].Content = payload[0][
            RESPONSE_BY.STRATEGY_EDGE
          ].Content?.map((c: IQueryContent) => {
            return { ...c, authors: GenrateRandomAuthor() };
          });
        }
      }
      return {
        ...state,
        chatDataSummmary: {
          table: [...state.chatDataSummmary.table, { ...payload[0] }],
        },
      };
    }
    case SET_RELEVANT_FILES: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable: any = { ...state.chatDataSummmary };
      if (tableLength > 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            relevantFiles: payload.map((c: any) => {
              return { ...c, authors: GenrateRandomAuthor() };
            }),
          }
        );
      }

      return {
        ...state,
        chatDataSummmary: { ...existingTable },
      };
    }
    case SET_CHAT_SUMMARY: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable: any = { ...state.chatDataSummmary };
      if (tableLength > 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            summary: payload,
          }
        );
      }
      return {
        ...state,
        chatDataSummmary: { ...existingTable },
      };
    }

    case SET_WHOLE_CHAT_TABLE: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable: any = { ...state.chatDataSummmary };
      if (tableLength > 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            showTable: payload,
          }
        );
      }
      return {
        ...state,
        chatDataSummmary: { ...existingTable },
      };
    }
    case SET_CLEAR_SUMMARY: {
      return {
        ...state,
        chatDataSummmary: "",
      };
    }
    case CLEAR_CHAT_HISTORY: {
      return {
        ...state,
        chatDataSummmary: {
          table: [],
        },
      };
    }

    case TOGGLE_LOADER: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case TOOBLE_CLEAR_LOADER: {
      return {
        ...state,
        isClearLoading: payload,
      };
    }

    case SET_DOWNLOAD_FILE_MESSAGE: {
      return {
        ...state,
        downloadFileMessage: payload,
      };
    }

    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: payload,
      };
    }

    case SET_CHUNK_DATA_LOADING: {
      if (payload === false) {
        const tableLength = state.chatDataSummmary.table.length;
        const existingTable: any = { ...state.chatDataSummmary };

        if (
          tableLength > 0 &&
          existingTable.table[tableLength - 1].SEResponse
        ) {
          let newsummary =
            existingTable.table[tableLength - 1].SEResponse.summary;
          if (
            newsummary.split(" ").pop() === "files" ||
            newsummary.split(" ").pop() === "files:"
          ) {
            newsummary = newsummary.substring(0, newsummary.lastIndexOf(" "));
          }
          existingTable.table[tableLength - 1].SEResponse = Object.assign(
            {},
            existingTable.table[tableLength - 1].SEResponse,
            {
              summary: newsummary,
            }
          );
        }
        return {
          ...state,
          chatDataSummmary: { ...existingTable },
          chunkLoading: payload,
        };
      } else {
        return {
          ...state,
          chunkLoading: payload,
        };
      }
    }
    default: {
      return state;
    }
  }
};
