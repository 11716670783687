import React, { useState } from "react";
import FooterModal from "./FooterModal";
import styled from "styled-components";
import scssVar from "../../../styles/variables.module.scss";
import { closeModalIcon } from "../../../app.constant";
import label from "../../../configs/ui/en.json";

const Footer = ({ length }: any) => {
  const [openModal, setOpenModal] = useState<"privacy" | "terms" | null>(null);

  return (
    <StyleFooter>
      <FooterModal
        show={!!openModal}
        type={openModal}
        onClose={(): void => setOpenModal(null)}
        headerIcon={
          <img
            // onClick={() => setOpenModal(null)}
            className="se-close"
            src={closeModalIcon}
          />
        }
      />
      <div className={length > 0 ? "footer-with-chat" : "footer-main"}>
        <div className="se-sidebar-footer">
          <div className="se-row">
            <div className="col-6">
              <p className="se-body-sm">{label.FOOTER_TEXT}</p>
            </div>
            <div className="col-6 text-right">
              <div
                className="se-h5"
                onClick={(): void => setOpenModal("privacy")}
              >
                Privacy Notice
              </div>
              <div
                className="se-h5"
                onClick={(): void => setOpenModal("terms")}
              >
                Terms of Use
              </div>

              <div className="se-h5">
                <a
                  href="https://go.ey.com/3Q1QnhB"
                  target="_blank"
                  className="engagement"
                >
                  Engagement Management Guidance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyleFooter>
  );
};

const StyleFooter = styled.div`
  display: flex;
  width: 100%;
  .footer-main {
    display: flex !important;
    overflow: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: ${scssVar.darkwhite};
    color: ${scssVar.darkblack};
    bottom: 0;
    border-top: 1px solid ${scssVar.gray};
    width: 100%;
    & .se-body-sm {
      color: ${scssVar.darkblack};
      font-size: 10px;
      padding-left: 50px;
      font-family: ${scssVar.fontInterstate};
      font-size: 10px;
      line-height: 16px;
    }
    & .se-h5 {
      color: ${scssVar.darkblack};
      cursor: pointer;
      display: inline-block;
      padding-right: 30px;
      font-family: ${scssVar.fontInterstate};
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: right;
      & .engagement {
        color: ${scssVar.darkblack};
        text-decoration: none;
      }
    }
    & .text-right {
      padding: 15px 0 0 0;
      text-align: right;
    }
    .se-sidebar-footer {
      width: 99%;
    }
    .se-row {
      margin-right: -12px;
      margin-left: -12px;
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .footer-with-chat {
    /* bottom: 0; */
    /* position: absolute; */
    display: flex !important;
    overflow: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: #ffffff;
    color: #1a1a24;
    /* bottom: 0; */
    border-top: 1px solid #747480;
    width: 100%;
    & .se-body-sm {
      color: ${scssVar.darkblack};
      font-size: 10px;
      padding-left: 50px;
      font-family: ${scssVar.fontInterstate};
      font-size: 10px;
      line-height: 16px;
    }
    & .se-h5 {
      color: ${scssVar.darkblack};
      cursor: pointer;
      display: inline-block;
      padding-right: 30px;
      font-family: ${scssVar.fontInterstate};
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: right;
      & .engagement {
        color: ${scssVar.darkblack};
        text-decoration: none;
      }
    }
    & .text-right {
      padding: 15px 0 0 0;
      text-align: right;
    }
    .se-sidebar-footer {
      width: 99%;
    }
    .se-row {
      margin-right: -12px;
      margin-left: -12px;
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  @media screen and (min-width: 1350px) {
    .footer-main {
      bottom: 0;
      position: fixed;
    }
  }
  @media screen and (min-width: 1500px) {
    .footer-with-chat {
      bottom: 0;
      position: fixed;
    }
  }
`;

export default Footer;
