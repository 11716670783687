export const SET_CHAT_HISTORY = "SET_CHAT_HISTORY";
export const CLEAR_CHAT_HISTORY_WATCHER = "CLEAR_CHAT_HISTORY_WATCHER";
export const GET_CHAT_HISTORY = "GET_CHAT_HISTORY";
export const CLEAR_CHAT_HISTORY = "CLEAR_CHAT_HISTORY";
export const TOGGLE_GPT_SIDEBAR = "TOGGLE_GPT_SIDEBAR";
export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const TOOBLE_CLEAR_LOADER = "TOOBLE_CLEAR_LOADER";
export const SET_DOWNLOAD_FILE_MESSAGE = "SET_DOWNLOAD_FILE_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_CHAT_SUMMARY = "SET_CHAT_SUMMARY";
export const SET_CLEAR_SUMMARY = "SET_CLEAR_SUMMARY";
export const SET_CHAT_TABLE = "SET_CHAT_TABLE";
export const SET_WHOLE_CHAT_TABLE = "SET_WHOLE_CHAT_TABLE";
export const SET_CHUNK_DATA_LOADING = "SET_CHUNK_DATA_LOADING";
export const SET_RELEVANT_FILES = "SET_RELEVANT_FILES";
export const GET_RELEVANT_FILES = "GET_RELEVANT_FILES";
export const SET_RELEVANT_FILES_WATCHER = "SET_RELEVANT_FILES_WATCHER";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
