import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { TENANT, CLIENT_ID, BACKEND_API_URL } from "./configs";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { IAuthProvider } from "react-aad-msal/dist/typings/interfaces";
import LoadingIndicator from "./component/LoadingIndicator";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { initializeSession } from "./services/session.service";
import { initConfig } from "./appConfig";
import { initApi } from "./services/api";
import { IAppConfig } from "./configs/IAppConfig";
import configureMsalAuthProvider from "./msalConfig";
import "./index.scss";
import { Provider } from "react-redux";
import { initFetch } from "./services/fetch";
import store from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Root: React.FC = () => (
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
window.addEventListener("DOMContentLoaded", () => {
  const appConfig = {
    activeDirectory: {
      tenant: TENANT,
      clientId: CLIENT_ID,
    },
  } as IAppConfig;

  initializeSession();
  initConfig(appConfig);
  initFetch();

  const searchObj = {
    authInfo: "",
  };

  // configureMsalAuthProvider(appConfig.activeDirectory, searchObj).then(
  //   (result) => {
  //     root.render(
  //       //@ts-ignore
  //       <AzureAD provider={result}>
  //         {({
  //           login,
  //           authenticationState,
  //         }: IAuthProvider): React.JSX.Element => {
  //           switch (authenticationState) {
  //             case AuthenticationState.Authenticated:
  //               return <Root />;
  //             case AuthenticationState.Unauthenticated: {
  //               login();
  //               return <></>;
  //             }
  //             case AuthenticationState.InProgress:
  //               return (
  //                 <div className="global-loder">
  //                   <div className="center-wrapper">
  //                     <LoadingIndicator />
  //                   </div>
  //                 </div>
  //               );
  //             default:
  //               return <Root />;
  //           }
  //         }}
  //       </AzureAD>
  //     );
  //   }
  // );
  root.render(<Root />);
});
reportWebVitals();
