import { AnyAction } from "redux";

export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const STORE_USER_PROFILE = "STORE_USER_PROFILE";

export function storeUserProfile(token: string): AnyAction {
  return {
    type: STORE_USER_PROFILE,
    value: token,
  };
}

export function fetchUserProfile(): AnyAction {
  return {
    type: FETCH_USER_PROFILE,
  };
}
