import { LoopReducer, loop, Cmd, Loop } from "redux-loop";
import * as userProfileActions from "../action/userProfileActions";

import { getUserProfile } from "../../services/userProfile.service";

import { AnyAction } from "redux";

import { createBrowserHistory } from "history";

export type IUserProfileState = {
  userData: {
    displayName: string;
    email: string;
    isExternal: boolean;
    profileImage: string;
    sid: string;
  };
  userRoles: Array<string> | undefined;
  tenantId: string | undefined;
  region: string | undefined;
};

export const initialState: IUserProfileState = {
  userData: {
    displayName: "",
    email: "",
    isExternal: false,
    profileImage: "",
    sid: "",
  },
  userRoles: undefined,
  tenantId: undefined,
  region: undefined,
};
export const browserHistory = createBrowserHistory({});

export const userProfileReducer: LoopReducer<IUserProfileState> = (
  state: IUserProfileState = initialState,
  action: AnyAction
): IUserProfileState | Loop<IUserProfileState> => {
  switch (action.type) {
    case userProfileActions.FETCH_USER_PROFILE:
      return loop(
        {
          ...state,
        },
        Cmd.list([
          Cmd.run(getUserProfile, {
            successActionCreator: userProfileActions.storeUserProfile,
            failActionCreator: userProfileActions.storeUserProfile,
            args: [],
          }),
        ])
      );

    case userProfileActions.STORE_USER_PROFILE:
      if (!action.value?.idToken) {
        return {
          ...state,
          userData: {
            displayName: initialState?.userData?.displayName,
            email: initialState?.userData?.email,
            isExternal: initialState?.userData?.isExternal,
            profileImage: action.value?.image
              ? `data:image/png;base64, ${action.value?.image}`
              : "",
            sid: "",
          },
        };
      }
      const { claims = {}, sid } = action.value?.idToken;
      const { name = "", preferred_username = "", acct } = claims;
      return {
        ...state,
        userData: {
          displayName: name || initialState?.userData?.displayName,
          email: preferred_username || initialState?.userData?.email,
          isExternal: !!acct || initialState?.userData?.isExternal,
          profileImage: action.value?.image
            ? `data:image/png;base64, ${action.value?.image}`
            : "",
          sid: sid ? sid : "",
        },
      };
    default:
      return state;
  }
};
