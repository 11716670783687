import React, { Fragment, ReactChild } from "react";
import styled from "styled-components";
import scssVar from "../../../../styles/variables.module.scss";
interface IOwnProps {}
const PrivacyModalContent: React.FC<IOwnProps> = () => {
  return (
    <StylePrivacy>
      <div>
        <b>Introduction</b> <br /> in This Privacy Notice is intended to
        describe the practices EY follows in relation to the Insight Edge tool
        (the "Tool") with respect to the privacy of all individuals whose
        personal data is processed and stored in the Tool. <br /> <br />
        <b>Who manages the Tool?</b>
        <br />
        "EY" refers to one or more of the member firms of Ernst & Young Global
        Limited ("EYG"), each of which is a separate legal entity and can act as
        a data controller in its own right. The entity that is acting as data
        controller by providing this Tool on which your personal data will be
        processed and stored is EYGM Limited, an EY global entity. The personal
        data you provide in the Tool is shared by EYGM Limited with one or more
        member firms of EYG (see "Who can access your information" section
        below). The Tool is hosted on servers in the Netherlands.
        <br /> <br />
        <b> Why do we need your information? </b>
        <br />
        The Tool enables EY TAS practitioners to analyze large market data sets
        in the digital/technology space across multiple metrics (including flow
        of investment funds, collaborations between companies and emerging
        technologies which are disrupting the value chains of EY's clients).
        Your personal data processed in the Tool is used as follows: to enable
        login to the tool. EY relies on the following basis to legitimize the
        processing of your personal data in the Tool: the information is
        necessary for the purposes of the legitimate interests pursued by the
        data controller or by a third party, except where such interests are
        overridden by the interests or fundamental rights and freedoms of the
        data subject which require protection of personal data. The provision of
        your personal data to EY is optional. However, if you do not provide all
        or part of your personal data, we may be unable to carry out the
        purposes for processing.
        <br /> <br />
        <b>What type of personal data is processed in the Tool? </b> The Tool
        processes these personal data categories: <br />
        Email; <br /> password (for the purpose of logging into the Tool);
        <br /> company name; and <br /> location. <br />
        This data is sourced from EY partners, employees or contractors
        directly. <br /> <br />
        <b>Sensitive Personal Data </b>
        <br />
        Sensitive personal data reveals your racial or ethnic origin, political
        opinions, religious or philosophical beliefs, trade union membership,
        genetic data, biometric data, data concerning health or data concerning
        sex life or sexual orientation. EY does not intentionally collect any
        sensitive personal data from you via the Tool. The Tool's intention is
        not to process such information. <br /> <br />
        <b>Who can access your information?</b> <br />
        Your personal data is accessed in the Tool by the following
        persons/teams: <br /> EY users (located globally and will have read-only
        access to the data in the Tool); <br />
        EY admin (located in London); and <br /> EY IT support (GDS for
        environment support). <br />
        The access rights detailed above involve transferring personal data in
        various jurisdictions (including jurisdictions outside the European
        Union) in which EY operates (EY office locations are listed at
        www.ey.com/ourlocations). EY will process your personal data in the Tool
        in accordance with applicable law and professional regulations in your
        jurisdiction. Transfers of personal data within the EY network are
        governed by EY's Binding Corporate Rules (www.ey.com/bcr). <br /> <br />
        <b>Data retention </b>
        <br /> Your personal data will be manually deleted in the event that you
        leave the firm. Your personal data will be retained in compliance with
        privacy laws and regulations. After the end of the data retention
        period, your personal data will be deleted. <br /> <br />
        <b>Security</b>
        <br />
        EY is committed to making sure your personal data is secure. To prevent
        unauthorized access or disclosure, EY has technical and organizational
        measures to safeguard and secure your personal data. All EY personnel
        and third parties EY engages to process your personal data are obliged
        to respect your data's confidentiality. <br /> <br />
        <b>Controling your personal data </b> <br /> EY will not transfer your
        personal data to third parties (other than any external parties referred
        to in section 6 above) unless we have your permission or are required by
        law to do so. You are legally entitled to request details of EY's
        personal data about you. To confirm whether your personal data is
        processed in the Tool or to access your personal data in the Tool,
        contact your usual EY representative or email your request to
        global.data.protection@ey.com. <br /> <br />
        <b>
          Rectification, erasure, restriction of processing or data portability
        </b>
        <br />
        You can confirm your personal data is accurate and current. You can
        request rectification, erasure, restriction of processing or a readily
        portable copy of your personal data by contacting your usual EY
        representative or by sending an e-mail to global.data.protection@ey.com{" "}
        <br /> <br />
        <b>Complaints</b> <br /> If you are concerned about an alleged breach of
        privacy law or any other regulation, contact EY's Global Privacy
        Officer, Office of the General Counsel, 6 More London Place, London, SE1
        2DA, United Kingdom or via email at global.data.protection@ey.com or via
        your usual EY representative. An EY Privacy Officer will investigate
        your complaint and provide information about how it will be handled and
        resolved. If you are not satisfied with how EY resolved your complaint,
        you have the right to complain to your country's data protection
        authority. You can also refer the matter to a court of competent
        jurisdiction. <br />
        <br /> <b> Contact us</b> <br /> If you have additional questions or
        concerns, contact your usual EY representative or email
        global.data.protection@ey.com .
      </div>
    </StylePrivacy>
  );
};

const StylePrivacy = styled.div`
  p {
    font-family: ${scssVar.fontInterstate};
    font-size: 12px;
    line-height: 20px;
    font-weight: lighter;
    color: rgb(196, 196, 205) !important;
  }
  div {
    font-family: ${scssVar.fontInterstate};
    font-size: 14px;
    line-height: 20px;
    color: rgb(196, 196, 205) !important;
  }
    .text-color{
        color: ${scssVar.darkwhite};
        & a{
            text-decoration: none;
            color:${scssVar.darkwhite};
        }
    }
    .m-b-15{
        margin-bottom: -15px;
    }
    .center_bold_text{
      font-weight: bold;
      text-align: center;
    }
}`;

export default PrivacyModalContent;
