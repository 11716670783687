import React from "react";
import styled from "styled-components";
import scssVar from "../../../../styles/variables.module.scss";
interface IOwnProps {}
const PrivacyModalContent: React.FC<IOwnProps> = () => {
  return (
    <StylePrivacy>
      <div>
        <b> IMPORTANT: </b> Insight Edge (the “Tool”) is a proprietary EY
        technology which is a cloud-based exploratory analytic dashboarding tool
        used to assist in identifying strategic, economic and
        operational-related risks across an ecosystem of relationships to
        provide a multi-dimensional view of threats and indicators of risks
        which could impact business relationships. Before you receive access to
        the Tool and its contents, you must read and accept the following Terms
        of Use and Privacy Notice. In accessing the Tool, you agree to be bound
        by such terms and conditions, including any modification to them from
        time to time, each time you receive any information as a result of your
        access to the Tool. Any breach of such terms and conditions may result,
        in our sole discretion and without limitation, in the suspension or
        termination of your access to the Tool. EY may without prior notice
        modify the contents and functionalities of the Tool or discontinue the
        operation, without prior notice to you, of all or portions of the Tool
        at any time in EY’s sole discretion.
        <br />
        <br />
        <b>Terms of Use</b> <br /> EY’s Relationship with You <br /> If you are
        an EY client and you will use the Tool to access an EY report that was
        prepared pursuant to an engagement agreement between you and EY, your
        access to the Tool (and the contents thereof, including any such report)
        is subject to the terms and conditions of your engagement agreement with
        EY. If you are not an EY client, or if you are an EY client with respect
        to matters unrelated to your current access to the Tool, your access to
        the Tool (and the contents thereof, including any EY reports) is subject
        to these Terms of Use and, where applicable, the terms and conditions of
        the release letter agreed between you and EY. In the event of any
        conflict between the terms of use set out in these Terms of Use and in
        any release letter, the terms set out in the release letter shall
        prevail. EY is providing the Tool to our client and performing our
        services under the terms of our agreement with our client. The scope of
        our services to our client is clear and distinct from that of any other
        advisor to the client and EY will act independently from any other
        advisor to our client (and, for the avoidance of doubt, any other third
        parties that are not EY’s direct subcontractors). EY will not be held
        liable for any work performed or services rendered by other advisors.
        You expressly acknowledge that any instruction to perform your services
        through the EY Tool comes directly from our client and that any
        interaction between you and EY should be treated as incidental. You
        acknowledge and agree that the Tool and its contents are intended only
        for EY’s client, and that you will not forward, distribute, reproduce,
        copy or publish any information contained in the Tool to any other
        person without our prior written consent. <br />
        <br />
        <b>Intellectual Property Rights.</b> <br />
        The Tool is confidential and proprietary to or licensed by EY. All
        intellectual property rights in the Tool, and in any modifications to
        the Tool created by EY, belong to EY or its licensors. You shall have no
        right to (and shall not), and you shall not permit any third parties to,
        sub-license, copy, adapt, reverse engineer, decompile, disassemble or
        modify any software used in the Tool in whole or in part, or in any way
        derive any source code from, or create any derivative work of, the Tool.
        <br />
        <br />
        <b>No Warranties </b> <br />
        The Tool is provided “AS IS,” and none of EY or any other party involved
        in the creation, production or delivery of the Tool makes any
        warranties, express or implied and whether by statue or otherwise, with
        respect to the Tool, including, without limitation, any implied warranty
        of satisfactory quality, merchantability, use of reasonable skill and
        care or fitness for any particular purpose or use, non-infringement,
        title, or that the operation of the Tool will be uninterrupted, error
        free or that it will be compatible with any of your hardware or
        software. You acknowledge that you shall be solely responsible for your
        use of the Tool. No responsibility or liability is or will be accepted
        by us in connection with your use of the Tool, including but not limited
        to the adequacy, accuracy, or completeness of the Tool or the output of
        the Tool or any conclusions or decisions that you reach through your use
        of the Tool. EY shall not be responsible for any maintenance, training,
        assistance, updates or support of any kind or nature related to the
        Tools.{" "}
        <br>
          <br />
        </br>{" "}
        <b>Your Use of the Tool</b> While using the Tool, users must: <br />
        <br />
        take all reasonable steps to ensure that none of the information in the
        Tool is visible to, or capable of being overlooked by, other persons;
        <br />
        not access the Tool from any place where the public has access either
        physically or electronically; <br /> not leave their computer or other
        communications device through which they access the Tool unattended
        whilst connected to the Tool; <br /> ensure that they close the browser
        and log out when they have finished using the Tool;
        <br /> not deface, mark, damage or destroy in any way any Information
        contained in the Tool; <br /> not attempt to disable the protection
        software associated with the Tool; <br /> not share their password for
        the Tool with anyone else, including any other viewer or user from its
        representatives. <br /> All users and viewers will be monitored by EY
        based on their log-in details; <br /> and comply immediately and fully
        with any instruction from, and submit to any reasonable security
        procedures required by EY. <br />
        You represent and warrant for yourself and on behalf of your authorized
        users that you will not use the Tool: (a) in any way that breaches any
        applicable law or regulation; (b) in any way that is fraudulent or has
        any fraudulent purpose; or (c) to knowingly transmit any data, send or
        upload any material that contains viruses, Trojan horses, worms, time
        bombs, spyware, adware or any other harmful programs or similar computer
        code designed to adversely affect the operation of any computer software
        or hardware. You also agree not to access without authority, interfere
        with, damage or disrupt (i) any part of the Tool; (ii) any equipment or
        network on which the Tool is stored; (iii) any software used in the
        Tool; or (iv) any equipment, network or software owned or used by any
        third party. You also acknowledge that you have implemented sufficient
        security measures to prevent any security violation that could result in
        damages to you or us. You will inform us without delay if you become
        aware of any potential or proven security risk, including but not
        limited to the use of user credentials by unauthorized parties, or if
        one of your authorized users leaves your employment such that their user
        access should be removed. <br />
        Except as expressly provided otherwise in these Terms of Use or in any
        engagement agreement or release letter with EY or as we may consent
        otherwise in writing, , you shall ensure that: (i) each of your
        authorized users keeps a secure password for his/her use of the Tool,
        (ii) such password shall be changed in accordance with our reasonable
        instructions, (iii) each of your authorized users shall keep his/her
        password confidential, (iv) each set of user credentials for the Tool is
        not used by more than one individual or shared among individuals and (v)
        you shall not provide any third party (including your affiliates, unless
        otherwise requested by you and agreed by us in writing) with access to
        the Tool. <br />
        <br />
        <b>Third Parties</b>
        <br /> We may at any time subcontract certain functions relating to the
        Tool to third parties who supply us with the hardware, software,
        services, products, programs and goods we need to operate and maintain
        the Tool. We do not assume any responsibility for any third-party
        hardware, software, services, products, programs or goods, including,
        without limitation, their performance or otherwise.
        <br /> <br />
        <b>The Tools and EY Reports</b> <br />
        Your access to the Tool to draft Reports or other information is solely
        for the purposes of a transaction involving our client. We assume no
        responsibility or liability whatsoever to you in respect of the contents
        and you agree to be bound by the terms and conditions of the engagement
        agreement between EY and our client or, where applicable, the terms and
        conditions of the release letter agreed between you and EY, relating to
        restrictions on the use and disclosure of our Report or any other
        information <br />
        <br />
        Data Content of the Tool <br />
        While we may have performed certain procedures on raw data in order to
        enable it to function appropriately with the Tool, we have not created
        the underlying data provided, uploaded or amended by our client or on
        its behalf and we do not have any responsibility to analyze, evaluate,
        verify or comment. To the extent that we use publicly available
        information or other third party sources, we have not verified the
        accuracy, reliability or completeness of such information or sources.
        Any content provided by you that may be posted or stored on the Tool
        shall be compliant with applicable laws and regulations and shall not
        infringe any third party rights, including but not limited to any
        intellectual property rights. You agree to take all necessary actions to
        ensure that the data uploaded to and/or used as part of the Tool has
        been properly saved. <br />
        <br />
        Representation and Acknowledgement <br />I hereby represent that: <br />
        <br />I am a person permitted to receive or access the Tool;
        <br /> I acknowledge and agree to be bound by the Terms of Use set forth
        above; <br />
        This acknowledgment, any non-contractual obligations arising out of or
        in connection with this acknowledgement and the relationship between the
        parties hereunder shall be governed by and construed in accordance with
        the laws governing EY’s engagement with its client.
        <br /> I agree that the courts of such jurisdiction are to have
        exclusive jurisdiction and submit to the exclusive jurisdiction of such
        courts. I hereby irrevocably waive, to the fullest extent permitted by
        law, any objection which I may now or hereafter have to such courts’
        exercise of personal jurisdiction over me or the laying of venue of any
        such proceeding brought in such a court and hereby waive any claim that
        any such proceeding brought in such a court has been brought in an
        inconvenient forum; and I acknowledge that BRETA is not a tool or
        capability that should solely be utilized for decision making. BRETA
        (the “Tool”) is a proprietary EY technology which is a cloud-based
        exploratory analytic dashboarding tool used to assist in identifying
        strategic, economic and operational-related risks across an ecosystem of
        relationships to provide a multi-dimensional view of threats and
        indicators of risks which could impact business relationships. Before
        you receive access to the Tool and its contents, you must read and
        accept the following Terms of Use and Privacy Notice. In accessing the
        Tool, you agree to be bound by such terms and conditions, including any
        modification to them from time to time, each time you receive any
        information as a result of your access to the Tool. Any breach of such
        terms and conditions may result, in our sole discretion and without
        limitation, in the suspension or termination of your access to the Tool.
        <br />
        <br />
        EY may without prior notice modify the contents and functionalities of
        the Tool or discontinue the operation, without prior notice to you, of
        all or portions of the Tool at any time in EY’s sole discretion. <br />
        <br />
        <br />
        Terms of Use
        <br /> EY’s Relationship with You
        <br /> If you are an EY client and you will use the Tool to access an EY
        report that was prepared pursuant to an engagement agreement between you
        and EY, your access to the Tool (and the contents thereof, including any
        such report) is subject to the terms and conditions of your engagement
        agreement with EY.
        <br /> If y o u are not an EY client, or if you are an EY client with
        respect to matters unrelated to your current access to the Tool, your
        access to t h e Tool (and the contents thereof, including any EY
        reports) is subjec t to these Terms of Use and, where applicable, the
        terms and condition s of the release letter agreed between you and EY.
        In the of any confl i ct between the terms of use set out in these Terms
        of Use and in any r e lease letter, the terms set out in the release
        lett prevail. EY is providing the Tool to our client and performing ou
        services under the t e rms of our agreement with our client. The scope
        our services to our cl i ent is clear and distinct from that o advisor
        to the client and EY will act independently from any oth advisor to our
        client (and, for the avoidance of doubt, any other th parties that are
        not EY’s direct sub c ontractors). EY will not be held liable for any
        work performed or ser v ices rendered by other advisors. You expressly
        acknowledge that any i n struction to perform your services through the
        EY Tool comes di r ectly from our client and that any interaction
        between you an d EY should be treated as incidental. You acknowledge and
        agree that the Tool and its contents are intended for EY’s client, and
        that you w i ll not forward, distribute, rep copy or publish any
        information co n tained in the Tool to any other person without our
        prior written consent.
        <br />
        <br /> Intellectual Property Right <br />
        The Tool is confidential and propriet a ry to or licensed by EY. All
        intellectual property rights in the T o ol, and in any modifications to
        the Tool created by EY, belong to EY o r its licensors. You shall have
        no right to (and shall not), and you shal l not permit any third parties
        to, sub-license, copy, adapt, reverse e n gineer, decompile, disassemble
        or modify any software used in the To o l in whole or in part, or in an
        derive any source code from, or create a n y derivative work of, <br />
        the Tool <br /> <br /> No Warranties <br /> The Tool is provided “AS
        IS,” a n d none of EY or any o party involved in the creation,
        production or deli v ery of the Tool any warranties, express or implied
        and whether by stat u e or otherwise, with respect to the Tool,
        including, without limitat i on, any imp warranty of satisfactory
        quality, merchantability, use o f reasonable skill and care or fitness
        for any particular p urpose or use, non-infringement, title, or that the
        oper a tion of the Tool will be uninterrupted, error free or that it
        will b e compatible with any of y hardware or software. You acknowledg e
        that you shall be solely responsible for your use of the Tool. N o
        responsibility or liability or will be accepted by us in connecti o n
        with your use of the Tool, including but not limited to the adequ a cy,
        accuracy, or completeness the Tool or the output of the Tool or any
        conclusions or decisions that you reach through your use of the Tool. E
        Y shall not be responsible any maintenance, training, assistance, update
        s or support of any ki nature related to the Tools. <br />
        <br />
        Your Use of the <br />
        Tool While using the Tool users must:
        <br />
        <br /> take all reasonable steps to en s ure that none of the
        information in the Tool is visible to, or c apable of being overlooked
        by, other persons;
        <br /> not access the Tool f r om any place where the public has access
        either physically or electron i cally;
        <br /> not leave their compute or other communications device thr o ugh
        which they access the Tool unattended whilst connected to t h e Tool;
        <br />
        ensure that they close the browser and log out when they have f i nished
        using the Tool;
        <br /> not deface, mark, damage or destroy in any w a y any Information
        contained in t Tool;
        <br /> not attempt to disable the protec t ion software associated with
        t Tool; <br />
        not share their password for the T o ol with anyone else, includin any
        other viewer or user from its represen t atives. All users and viewers
        will be monitored by EY based on th eir log-in details;
        <br /> and comply immediately and fully with any inst ruction from, and
        submit to any reasonable security procedures required b y EY.
        <br />
        <br /> You represent and warran for yourself and on behalf of your auth
        orized users that you will no use the Tool: (a) in any way that b
        reaches any applicable law or regulation; (b) in any way that is
        fraudulent or has any fraudulent purpose; or (c) to knowingly transmit
        any data, send or upload any material that contains vir uses, Trojan
        horses, worms, time bombs, spyware, adware or any o t her harmful
        programs or similar computer code designed to adversely af f ect the
        operation of any computer software or hardware. You also a gree not to
        access without authority, interfere with, damage or disru pt (i) any
        part of the Tool; (ii) any equipment network on which the Tool is
        stored; (iii) any software used in the Tool; or (iv) any eq uipment,
        network or software owned or used by an third party. You also
        acknowledge that you have implemented suff security measures to prevent
        a n y security violation that could result i damages to you or us. You w
        i ll inform us without delay if you become aware of any potential o r
        proven security risk, including but not limited to the use of user c
        redentials by unauthorized parties, or i one of your authorized users le
        a ves your employment such that their access should be removed. <br />
        <br />
        Excep t as expressly provided otherwise in these Terms of Use or in any
        eng a gement agreement or release letter w EY or as we may consent
        otherwise in writing, , you shall ensure th (i) each of your authorized
        users k e eps a secure password for his/her use of the Tool, (ii) such
        password shall be changed in accordance wit our reasonable instructions,
        (iii) e ach of your authorized users shall keep his/her password
        confidentia l , (iv) each set of user credentials for the Tool is not
        used by mo r e than one individual or shared among individuals and (v)
        you sh a ll not provide any third party (including your affiliates,
        unless ot h erwise requested by you and agreed by u writing) with access
        to th e Tool.
        <br />
        <br /> Third Parties
        <br /> We may at any time subcontract certain funct i ons relating to
        the Tool to third part supply us with the hardware, sof t ware,
        services, products, programs an goods we need to operate and m a intain
        the Tool. We do not assume responsibility for any third- p arty
        hardware, software, services, products, programs or goo d s, including,
        without limitation, their performance or otherwise.
        <br />
        <br /> T he Tools and EY Reports <br />
        Your access to the Tool to draft Reports or o t her information is
        solely for the purposes of a transaction involv i ng our client. We
        assume no responsibility or liability whatsoever to y o u in respect of
        the contents and you agree be bound by the terms and co n ditions of the
        engagement agreement between EY and our client or, where applicable, the
        terms and conditions of t release letter agreed betwe e n you and EY,
        relating to restrictions on the use and disclosure of our Report or any
        other information <br />
        <br />
        Data Content of the Tool <br />W h ile we may have performed certain
        procedur raw data in order to enable i t to function appropriately with
        the To we have not created the underlyi n g data provided, uploaded or
        amended our client or on its behalf an d we do not have any
        responsibility to analyze, evaluate, verify or co m ment. To the extent
        that we use publ available information or other thir d party sources, we
        have not the accuracy, reliability or comple t eness of such information
        or sources. Any content provided by yo u that may be posted or store
        Tool shall be compliant with applicable l a ws and regulations and shall
        not infringe any third party rights, inc l uding but not limited to any
        intellectual property rights. You agree to take all necessary actions to
        ensure that the data uploaded to and/o r used as part of the been
        properly saved.
        <br />
        <br /> Representation and Acknowledgement
        <br /> I hereby represent that:
        <br /> I am a person permitted to receiv e or access the Tool;
        <br />
        acknowledge and agree to be bound by the Terms o f Use set forth above
        <br />
        This acknowledgment, any non-contractual obligati o ns arising out of in
        connection with this acknowledgement and the relat i onship between th
        parties hereunder shall be governed by and construed i n accordance with
        the laws governing EY’s engagement with its clien t . I agree that
        courts of such jurisdiction are to have exclusive j urisdiction an
        submit to the exclusive jurisdiction of such co urts. I hereby
        irrevocably waive, to the fullest extent permitted by law, any object
        which I may now or hereafter have to such courts’ e xercise of personal
        jurisdiction over me or the laying of venue o f any such proceeding
        brought in such a court and hereby waive any claim that any such
        proceeding brought in such a court has be en brought in an inconvenient
        forum; and I acknowledge that BRETA is not a tool or capability that
        should solely be utilized for decision making.
      </div>
    </StylePrivacy>
  );
};

const StylePrivacy = styled.div`
  p {
    font-family: ${scssVar.fontInterstate};
    font-size: 12px;
    line-height: 20px;
    font-weight: lighter;
    color: rgb(246, 246, 250) !important;
  }
  h4 {
    font-family: ${scssVar.fontInterstate};
    font-size: 14px;
    line-height: 20px;
    color: rgb(246, 246, 250) !important;
  }
  .text-color {
    color: ${scssVar.darkwhite};
    & a {
      text-decoration: none;
      color: ${scssVar.darkwhite};
    }
  }
  .m-b-15 {
    margin-bottom: -15px;
  }
  .term-modal {
    .motif-h4,
    .motif-h5 {
      font-family: ${scssVar.fontInterstate};
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      color: ${scssVar.gray2} !important;
    }
    .motif-h6 {
      font-family: ${scssVar.fontInterstate};
      font-size: 12px;
      line-height: 20px;
      color: ${scssVar.gray2} !important;
      font-weight: lighter;
    }

    .terms-outer-div {
      display: flex;
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: baseline;
      .terms-sr-number {
        min-width: 60px;
        width: 60px;
        font-family: ${scssVar.fontInterstate};
        font-size: 12px;
        line-height: 20px;
        text-transform: none;
        color: ${scssVar.gray2} !important;
        font-weight: lighter;
      }
      .terms-sr-number-main {
        min-width: 60px;
        width: 60px;
        font-family: ${scssVar.fontInterstate};
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        color: ${scssVar.gray2} !important;
        font-weight: bold;
      }
    }
    .terms-sub-child {
      display: flex;
      flex-direction: column !important;
    }
  }
`;

export default PrivacyModalContent;
