import { logoutandClearSession } from "./session.service";
import { authProvider } from "../msalConfig";
import { IdTokenResponse } from "react-aad-msal";
import apiRoute from "../configs/services/apis";
let authTokenRequest: Promise<IdTokenResponse> | null;

const fomatted = (response: any) => {
  return response.reduce((a: any, c: any) => {
    if (c?.status === "fulfilled" && a?.status === "fulfilled")
      return {
        ...a.value,
        ...(typeof c?.value === "string" ? { image: c.value } : c.value),
      };
    if (c?.status === "fulfilled")
      return {
        ...(typeof c?.value === "string" ? { image: c.value } : c.value),
      };
    if (c?.status === "fulfilled") {
      return a.value;
    }
    return {};
  });
};

export const getAuthToken = (): Promise<IdTokenResponse> => {
  if (!authTokenRequest) {
    authTokenRequest = authProvider.getIdToken();
  }
  return authTokenRequest;
};

export const getProfilePicture = async (): Promise<any> => {
  const requestOptions = {
    method: "GET",
  };
  const response = await fetch(apiRoute.PHOTO_URL, requestOptions);
  if (response.status === 401) {
    logoutandClearSession();
  }
  return await response.json();
};

export const getUserProfile = async (): Promise<any> => {
  try {
    const response = await Promise.allSettled([
      // await getAuthToken(),
      await getProfilePicture(),
    ]);
    return fomatted(response);
  } catch (err) {
    throw err;
  }
};
