import React, { useState, Fragment } from "react";
import styled from "styled-components";
import Modal from "../common/modal/Modal";
import LoadIndicator from "../common/LoadIndicator";
import { ModalType } from "../../modals/ModalType";
import sassVar from "../../styles/variables.module.scss";
import { logoutModalIcon } from "../../app.constant";
import { logoutandClearSession } from "../../services/session.service";
import { useDispatch } from "react-redux";
import { resetUserRole } from "../../redux/action/userRolesActions";

const { lightgray, fontInterstate, logoutModalWidth } = sassVar;
interface IOwnProps {
  show: boolean;
  onClose: () => void;
  headerIcon: React.ReactChild;
}

const LogoutModal: React.FC<IOwnProps> = ({
  show,
  onClose,
  headerIcon,
}: IOwnProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(resetUserRole());
    setIsLoading(true);
    logoutandClearSession();
  };

  return (
    <Modal
      customClass="silogoutmodal"
      isOpen={show}
      type={ModalType.AdvancedSearch}
      headerTitle="Logout"
      actionBtnEnabled={!isLoading}
      actionBtnText="Log Out"
      onActionBtnClicked={onLogout}
      onCloseBtnClicked={onClose}
      headerIcon={headerIcon}
      showHeaderIcon={true}
      width={+logoutModalWidth}
    >
      <StyledLogoutModal className="noto-sans center">
        {isLoading ? (
          <LoadIndicator />
        ) : (
          <Fragment>
            <div className="se-logout">
              <img
                alt="sign-out"
                className="icon-i-sign-out"
                src={logoutModalIcon}
              />
              <div className="confirmation-text">
                Are you sure you want to log out?
              </div>
            </div>
          </Fragment>
        )}
      </StyledLogoutModal>
    </Modal>
  );
};

const StyledLogoutModal = styled.div`
  padding: 20px 0;
  color: ${lightgray};
  .se-logout {
    margin: 15px 0px 15px 0;
    text-align: left;
  }
  & .text-center {
    text-align: center;
  }
  .icon-i-sign-out {
    display: inline;
    font-size: 3.4375rem;
    margin-left: 2px;
  }
  .confirmation-text {
    margin-top: 1.375rem;
    margin-bottom: 1.875rem;
    letter-spacing: 0;
    line-height: 22px;
    display: inline;
    vertical-align: 7px;
    margin-left: 14px;
    font-family: ${fontInterstate};
    font-size: 1.125rem;
    line-height: 22px;
  }
`;

export default React.memo(LogoutModal);
