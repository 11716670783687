export enum HttpStatusCode {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  Locked = 423,
  InternalServerError = 500,
  Forbidden = 403,
  TimedOut = 504,
}
