import { useDispatch } from "react-redux";

import { Routes, Route, Navigate } from "react-router-dom";
import { IAccountInfo } from "react-aad-msal/dist/typings/interfaces";
import TranscriptLanding from "./pages/transcripts-home";
import "./App.css";
import { useEffect, useState } from "react";
import { APP_ENVIRONMENT, INSIGHT_EDGE_URL } from "./configs";
import { ENVIRONMENTS } from "./app.constant";
import { fetchUserProfile } from "./redux/action/userProfileActions";

type IAccountInfoProps = {
  accountInfo?: IAccountInfo;
};
const App = ({ accountInfo }: IAccountInfoProps) => {
  const [loader, setLoader] = useState<boolean>(true);
  const dispatch = useDispatch();
  
  useEffect(() => {
    // dispatch(fetchUserProfile());
  }, []);

  useEffect(() => {
    if (APP_ENVIRONMENT !== ENVIRONMENTS.LOCAL && !window.opener) {
      INSIGHT_EDGE_URL && window.open(INSIGHT_EDGE_URL, "_self");
    } else {
      setLoader(false);
    }
  }, []);

  return (
    <>
      {" "}
      {!loader && (
        <Routes>
          <Route
            path="/landing-page"
            element={<Navigate to="/" replace />}
          ></Route>
          <Route path="transcript-view" element={<TranscriptLanding />}></Route>
          <Route path="/" element={<TranscriptLanding />}></Route>
        </Routes>
      )}
    </>
  );
};

export default App;
