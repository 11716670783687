import React from "react";
import styled from "styled-components";
import sassVars from "../../styles/variables.module.scss";
import { extractInitials } from "../common/AvatarInitials";

const {
  gray3A,
  yellow,
  gray6,
  gray50,
  gray,
  lightbrown,
  darkwhite,
  lightBlue,
  fontInterstate,
} = sassVars;

interface IOwnProps {
  isActive: boolean;
  userData: { profileImage: string; displayName: string; shortName?: string };
  profileMenuList: Array<{ id: number; item: string; icon: string }>;
  disableHelpMenu: boolean;
  dropdownRef?: React.Ref<HTMLDivElement>;
  onClickMenu: (e: React.SyntheticEvent<HTMLElement>, item: string) => void;
}
const ProfileMenu: React.FC<IOwnProps> = ({
  profileMenuList,
  userData,
  dropdownRef,
  isActive,
  onClickMenu,
}: IOwnProps) => {
  return (
    <ProfileStyled>
      <nav
        ref={dropdownRef}
        className={`menu ${isActive ? "active" : "inactive"}`}
      >
        <div className="top"></div>
        <ul>
          <li className=" se-dropdown-item">
            <div className="se-avatar se-avatar-size-small ">
              <span className="se-avatar-initials" role="img" aria-label="">
                {extractInitials(userData?.displayName)}
              </span>
            </div>
            {userData?.displayName}
          </li>
        </ul>
        <ul>
          {profileMenuList.length > 0 &&
            profileMenuList.map((menu) => {
              return (
                <li
                  onClick={(e) => {
                    onClickMenu(e, menu.item);
                  }}
                  key={menu.id}
                  className="widget-menu-list"
                >
                  <img
                    src={menu.icon}
                    alt="Menu Icons"
                    className={menu.id === 2 ? "contactUs" : ""}
                  />
                  &nbsp; {menu.item}
                </li>
              );
            })}
        </ul>
      </nav>
    </ProfileStyled>
  );
};
export default ProfileMenu;

const ProfileStyled = styled.div`
      & .menu {
        background: ${gray3A};
        border-radius: 1px;
        position: absolute;
        top: 61px;
        right: 1px;
        width: 14.7rem;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
      & .se-dropdown-item {
      color: ${darkwhite} !important;
      outline: ${gray50} !important;
      z-index: 2;
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 0px;
      font-size: 14px;
      clear: both;
      text-align: inherit;
      text-decoration: none;
      white-space: nowrap;
      border: none;
      outline: 1px solid transparent;
      font-family: ${fontInterstate};
      background: transparent;
      border-bottom: 1px solid ${gray};
      padding-bottom: 0px  !important ;
    }
   & .se-avatar-initials {
       font-size: 0.563rem;
       line-height: 22px;
       display: flex;
       align-items: center;
       justify-content: center;
       border-radius: 50%;
       position: absolute;
       width: 1.5rem;
      height: 1.5rem;
      background:${lightBlue};
      font-family: ${fontInterstate};
      font-size: 0.688rem;
      padding: 1px 0px 0px 1px;
    }
   }
      & .menu.active {
        opacity: 1;
        visibility: visible;
        z-index: 999;
        position: absolute;
      }
      & .menu ul {
        list-style: none;
        position: relative;
        right: 0px;
        padding: 0;
        margin: 0;
      }
      & .menu li {
        text-align: left;
        cursor: pointer;
        outline: 1px solid transparent;
        font-weight: lighter;
        font-family: ${fontInterstate};
        font-size: 14px;
        line-height: 16px;
        padding-left: 1rem;
        padding-left: 0.5rem;
        padding-bottom: 0.7rem;
      }
      & .menu .widget-menu-list:hover {
        color: ${yellow};
        background: ${gray6};
      }
      & .menu li a {
        text-decoration: none;
        color: ${lightbrown};
        padding: 15px 20px;
        display: block;
      }
      & .menu li img {
        position: relative;
        top: 9px;
      }
    }
  
    & .se-avatar-size-small {
      height: 2.71429rem;
      width: 2.71429rem;
    }
    & .se-avatar {
      border-style: solid;
      border: 0.14286rem solid transparent;
      position: relative;
      border-radius: 50%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 2px;
      text-transform: uppercase;
    }
    
    & .contactUs {
    height: 24px;
    width: 20px;
  }
    }
    

  
  `;
