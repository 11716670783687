import React, { ReactElement, ReactNode, ReactChild, Fragment } from "react";
// @ts-ignore
import ReactModal, { Styles } from "react-modal";
import styled from "styled-components";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import { ModalType } from "../../../modals/ModalType";
import sassVars from "../../../styles/variables.module.scss";
const { alternateblack, gray40 } = sassVars;
export interface IOwnProps {
  isOpen: boolean;
  type: ModalType;
  width?: number;
  children: ReactNode;
  headerTitle: string;
  actionBtnEnabled: boolean;
  actionBtnText?: string;
  onActionBtnClicked?: () => void;
  onCloseBtnClicked: () => void;
  hideCloseIcon?: boolean;
  isLoading?: boolean;
  showModalFooter?: boolean;
  showHeaderToolTip?: boolean;
  headerToolTip?: string;
  showHeaderIcon?: boolean;
  headerIcon?: ReactChild;
  customClass?: string;
  footerClass?: string;
}

const getPortalStyles = (width: number): Styles => {
  const obj: Styles = {
    content: {
      backgroundColor: `${alternateblack}`,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: `${width}px`,
      boxShadow: "0 0 30px 0 rgba(0,0,0,0.7)",
      right: "auto",
      bottom: "auto",
      border: `1px solid ${gray40}`,
      borderRadius: "none",
      padding: 0,
    },
    overlay: {
      backgroundColor: "rgba(26, 26, 36, 0.8)",
      zIndex: 100,
    },
  };

  return obj;
};

const Modal: React.FC<IOwnProps> = ({
  isOpen,
  type,
  width = window.innerWidth * 0.3,
  children,
  headerTitle,
  actionBtnEnabled,
  actionBtnText = "Create",
  onActionBtnClicked,
  onCloseBtnClicked,
  hideCloseIcon,
  isLoading,
  showModalFooter = true,
  showHeaderToolTip = false,
  headerToolTip = "",
  showHeaderIcon,
  headerIcon,
  customClass,
  footerClass = "",
}: IOwnProps): ReactElement => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      style={getPortalStyles(width)}
      className={`se-react-model se-model-${type} ${
        customClass ? customClass : ""
      }`}
    >
      <ModalHeader
        headerTitle={headerTitle}
        onCloseBtnClicked={onCloseBtnClicked}
        hideCloseIcon={hideCloseIcon}
        showHeaderToolTip={showHeaderToolTip}
        headerToolTip={showHeaderToolTip ? headerToolTip : ""}
        showHeaderIcon={showHeaderIcon}
        headerIcon={showHeaderIcon ? headerIcon : <Fragment></Fragment>}
      />

      <StyledModalContentWrapper>
        {children}
        {showModalFooter && (
          <ModalFooter
            type={type}
            actionBtnEnabled={actionBtnEnabled}
            actionBtnText={actionBtnText}
            onActionBtnClicked={onActionBtnClicked}
            onCloseBtnClicked={onCloseBtnClicked}
            isLoading={isLoading}
            footerClass={footerClass}
          />
        )}
      </StyledModalContentWrapper>
    </ReactModal>
  );
};

Modal.displayName = "Modal";

const StyledModalContentWrapper = styled.div`
  max-height: calc(90vh - 100px);
  overflow-y: auto;
  word-wrap: break-word;
`;

export default Modal;
