import { useDispatch } from "react-redux";
import {
  clearDownloadFileMessage,
  setErrorMessage,
  clearErrorMessage,
} from "../redux/action";
import { exportChatToPPT } from "../utils/exportChatToPPT";
import exportChatToWord from "../utils/exportChatToWord";
import { RESPONSE_BY } from "../configs/type/types";

const useExportToFile = () => {
  const dispatch = useDispatch();
  const csvExport = async (
    query: string | undefined,
    source: RESPONSE_BY.OPENAI | RESPONSE_BY.STRATEGY_EDGE
  ) => {
    // try {
    //   await exportToCSV(query, source);
    // } catch (error) {
    //   dispatch(clearDownloadFileMessage());
    //   dispatch(setErrorMessage("Something went wrong"));
    //   setTimeout(() => {
    //     dispatch(clearErrorMessage());
    //   }, 5000);
    // }
  };
  return { exportChatToPPT, exportChatToWord };
};

export default useExportToFile;
