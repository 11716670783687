import React from "react";
import "./ExampleQueries.scss";
interface IOwnProps {
  onClickHandler?: () => void;
}

const ExampleQueries: React.FC<IOwnProps> = () => {
  return (
    <div className="exapmles">
      <div>
        <p
          style={{
            fontSize: "14px",
            marginLeft: "5%",
            marginBottom: "2%",
            color: "white",
          }}
        >
          Try these examples
        </p>
      </div>
      <div className="exapmles__queries">
        <div className="exapmles__query">
          <p>What is the global plant-based protein market size?</p>
        </div>

        <div className="exapmles__query">
          <p style={{ paddingLeft: "5%", paddingTop: "3%" }}>
            How is the sustainable fiber value chain segmented?
          </p>
        </div>

        <div className="exapmles__query">
          <p style={{ paddingLeft: "5%", paddingTop: "3%" }}>
            Which segment is fast growing in freeze-dried pet food?
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExampleQueries;
