import React, { useEffect, useRef, useState } from "react";
import { IAccountInfo } from "react-aad-msal";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import DownloadIcon from "../../assets/download.svg";
import ChatReply from "../../component/ChatReply";
import InputBox from "../../component/InputBox";
import LinkButton from "../../component/LinkButton/LinkButton";
import SendMessage from "../../component/SendMessage";
import { HttpStatusCode } from "../../configs/base/enum";
import PageConfig from "../../configs/ui/en.json";
import { checkOnySpecialCharacter, checkOnlySpaces } from "../../utils";
import {
  getCurrentSessionId,
  initializeSession,
} from "../../services/session.service";
import { IQuery, RESPONSE_BY } from "../../configs/type/types";
import LABELS from "../../configs/ui/en.json";
import useExportToFile from "../../hooks/useExportToFile";
import {
  clearChatHistoryWacther,
  clearDownloadFileMessage,
  setChatHistory,
  setChatSummary,
  setChunkLoading,
  setClearLoader,
  setDownloadFileMessageWord,
  setWhoelChatTable,
  toggleLoader,
  setRelevantFiles,
} from "../../redux/action";
import { authProvider } from "../../msalConfig";
import { getAuthorizationToken } from "../../utils";
import "./TranscriptsInsight.scss";
import apiRoutes from "../../configs/services/apis";

type TranscriptsInsightProps = {
  accountInfo?: IAccountInfo;
};

const fileRegex = new RegExp("files:", "i");

const timeoutError = [
  {
    [RESPONSE_BY.STRATEGY_EDGE]: {
      summary: LABELS.chat_reply.SE_TOKEN_ERROR_MSG,
      content: [],
      responseType: "error",
      sources: ["Discover"],
    },
  },
];

const replyServerError = [
  {
    [RESPONSE_BY.STRATEGY_EDGE]: {
      summary: LABELS.chat_reply.OPENAI_ERROR_MSG,
      content: [],
      responseType: "error",
      sources: ["Discover"],
    },
  },
];
let chatResponse: string = "";
const TranscriptsInsight = ({ accountInfo }: TranscriptsInsightProps) => {
  const [seQuery, setSeQuery] = useState<IQuery[]>([]);
  const { chatDataSummmary, isClearLoading, isLoading, chunkLoading } =
    useSelector((state: RootStateOrAny) => state.openAi);
  const [queryText, setQueryText] = useState("");
  const refToScroll = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLTextAreaElement>(null);
  const [isValidQuery, setIsValidQuery] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { exportChatToWord } = useExportToFile();

  const getToken = async () => {
    const res = await authProvider?.getIdToken();
    return res.idToken.rawIdToken;
  };

  const handleOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (checkOnySpecialCharacter(ev.target.value)) setIsValidQuery(false);
    else {
      setIsValidQuery(true);
    }
    setQueryText(ev.target.value);
  };

  const scrollToBottom = () => {
    refToScroll?.current?.scroll({
      left: 0,
      top: refToScroll.current.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setSeQuery(() => chatDataSummmary?.table);
  }, [chatDataSummmary]);

  useEffect(() => {
    initializeSession();
  }, []);

  useEffect(() => {
    refInput.current?.focus();
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [seQuery]);

  //API call to get chat response with chat query
  const handleSendMessage = (
    ev:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading && isValidQuery) {
      if (queryText || query) {
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query,
          },
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
        sendChatQuery((queryText || query || "").trim());
        setQueryText("");
        dispatch(toggleLoader(true));
      }
    }
  };

  //this will reset the chat and chat summary
  const handleResetMessage = async () => {
    refToScroll?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
    try {
      dispatch(setClearLoader(true));
      dispatch(clearChatHistoryWacther());
      setQueryText("");
      dispatch(setClearLoader(false));
    } catch (error) {
      console.log(error);
    }
  };
  const setRelevantTableData = (str: string) => {
    try {
      const splitwiseFiles = str.split(fileRegex);
      if (splitwiseFiles.length > 1) {
        const filesstring = splitwiseFiles[1];
        const result = JSON.parse(
          filesstring.replace("\n", "").replace("\t", "")
        );

        if (result.length) {
          const updatedRes = result.map((res: any) => {
            let pagelist = res.page_number.reduce(
              (newPages: any, page: any) => {
                if (newPages.includes(page)) {
                  return newPages;
                }
                return [...newPages, page];
              },
              []
            );
            return { ...res, page_number: pagelist };
          });
          dispatch(setRelevantFiles(updatedRes));
        }
      }
    } catch (er) {
      console.log(er);
    }
  };

  const sendChatQuery = async (summary: string) => {
    scrollToBottom();
    const sessionId = getCurrentSessionId();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "session-id": sessionId || "",
        "jwt-token": accountInfo?.jwtIdToken || "",
        "user-email": accountInfo?.account.userName || "",
        "user-id": "Ey-User",
        Authorization: `Bearer ${sessionId}`,  // await  getAuthorizationToken()
      },
      body: JSON.stringify({
        query: summary,
      }),
    };

    let check = false;
    let isRelevantFiles: boolean = false;
    const controller = new AbortController();
    try {
      chatResponse = "";
      const response: any = await fetch(apiRoutes.SUMMARY_CHUNK, {
        ...requestOptions,
        signal: controller.signal,
      });

      const { status, statusText } = response;
      if (
        status === HttpStatusCode.InternalServerError ||
        status === HttpStatusCode.TimedOut ||
        status === HttpStatusCode.BadRequest ||
        statusText === PageConfig.SERVER_ERROR
      ) {
        dispatch(setChatHistory([...replyServerError]));
        dispatch(toggleLoader(false));
        scrollToBottom();
      } else {
        const reader = response.body.getReader();
        reader
          .read()
          .then(function pump({ done, value }: any) {
            dispatch(setChunkLoading(true));
            if (done) {
              dispatch(setChunkLoading(false));
              dispatch(setWhoelChatTable(true));
              dispatch(toggleLoader(false));
              scrollToBottom();
              setRelevantTableData(chatResponse);
              return;
            }

            let decodedChunks = new TextDecoder().decode(value);
            chatResponse = chatResponse.concat(decodedChunks);

            if (!check) {
              dispatch(
                setChatHistory([
                  { [RESPONSE_BY.STRATEGY_EDGE]: { Content: [] } },
                ])
              );

              let newChunkdata = decodedChunks.split(fileRegex);
              if (newChunkdata.length > 1) {
                isRelevantFiles = true;
                dispatch(setChatSummary(chatResponse.split(fileRegex)));
              } else {
                dispatch(setChatSummary(`${chatResponse}`));
              }
              check = true;
            } else {
              if (!isRelevantFiles) {
                let newChunkdata = chatResponse.split(fileRegex);
                if (newChunkdata.length > 1) {
                  isRelevantFiles = true;
                  dispatch(setChatSummary(chatResponse?.split(fileRegex)[0]));
                } else {
                  dispatch(setChatSummary(chatResponse));
                  scrollToBottom();
                }
              }
            }
            return reader.read().then(pump);
          })
          .catch((err: any) => {
            dispatch(setChunkLoading(false));
            scrollToBottom();
            dispatch(toggleLoader(false));
            console.error(":::::errore", err);
          });
      }
    } catch (error) {
      dispatch(setChunkLoading(false));
      if (controller.signal.aborted) {
        dispatch(setChatHistory([...timeoutError]));
        dispatch(toggleLoader(false));
        scrollToBottom();
      } else {
        dispatch(setChatHistory([...replyServerError]));
        dispatch(toggleLoader(false));
        scrollToBottom();
      }
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
      handleSendMessage(ev);
    }
  };

  const handleDocDownload = async () => {
    dispatch(setDownloadFileMessageWord());

    await exportChatToWord(seQuery);
    dispatch(clearDownloadFileMessage());
  };

  return (
    <>
      {seQuery?.length > 0 && (
        <div className="suggested-queries">
          <div className="download-btn-box">
            <LinkButton
              onClick={() => handleDocDownload()}
              disabled={isLoading || isClearLoading}
            >
              <img src={DownloadIcon} /> Download
            </LinkButton>
          </div>
          <div className="scrollable-area" ref={refToScroll}>
            {seQuery?.map((chat: any, ind: number) => {
              let data =
                chat[RESPONSE_BY.STRATEGY_EDGE] ||
                chat[RESPONSE_BY.OPENAI] ||
                chat[RESPONSE_BY.USER];
              const response =
                seQuery?.length > ind + 1 &&
                seQuery[ind + 1][RESPONSE_BY.STRATEGY_EDGE]
                  ? seQuery[ind + 1][RESPONSE_BY.STRATEGY_EDGE]
                  : null;
              return Object.keys(chat).includes(RESPONSE_BY.USER) ? (
                <SendMessage
                  key={ind}
                  message={data}
                  isNew={true}
                  handleSendMessage={handleSendMessage}
                  loader={isLoading}
                  dataExport={{ query: data?.summary, response }}
                />
              ) : (
                <ChatReply
                  chatData={{ ...chat }}
                  key={ind}
                  isNew={true}
                  isSE={Object.keys(chat).includes(RESPONSE_BY.STRATEGY_EDGE)}
                  messageLoading={isLoading}
                  chunkLoading={chunkLoading}
                  recentMessage={ind === seQuery.length - 1}
                />
              );
            })}
            {isLoading && !chunkLoading && (
              <ChatReply loader={isLoading} isNew={true} isSE={true} />
            )}
          </div>
        </div>
      )}

      <InputBox
        selectedSegment={true}
        queryText={queryText}
        refInput={refInput}
        hasQueries={seQuery?.length > 0}
        handleOnChange={handleOnChange}
        handleResetMessage={handleResetMessage}
        handleSendMessage={handleSendMessage}
        handleKeyDown={handleKeyDown}
        disabled={isLoading}
        isValidQuery={isValidQuery}
      />
    </>
  );
};

export default TranscriptsInsight;
