import { AnyAction } from "redux";
import { AxiosError } from "axios";
import { IUserRolesResponse } from "../../modals/IUserRoles";
export const FETCH_USER_ROLE = "FETCH_USER_ROLE";
export const STORE_USER_ROLE = "STORE_USER_ROLE";
export const FETCH_USER_ROLE_FAILED = "FETCH_USER_ROLE_FAILED";
export const RESET_USER_ROLE = "RESET_USER_ROLE";

export function fetchUserRoles(): AnyAction {
  return {
    type: FETCH_USER_ROLE,
  };
}
export function storeUserRole(userRoleObj: IUserRolesResponse): AnyAction {
  return {
    type: STORE_USER_ROLE,
    userRoleObj,
  };
}
export function resetUserRole(): AnyAction {
  return {
    type: RESET_USER_ROLE,
    userRole: undefined,
    tenantId: undefined,
    region: undefined,
  };
}
export function fetchUserRoleFailure(axiosErr: AxiosError): AnyAction {
  const response = axiosErr.response;
  if (response?.status === 451 || response?.status === 401) {
    return { type: "NO_OP" };
  }

  return {
    type: FETCH_USER_ROLE_FAILED,
    userRole: undefined,
    tenantId: undefined,
    region: undefined,
  };
}
