import React, { ReactChild } from "react";
import styled from "styled-components";
import sassVars from "../../../styles/variables.module.scss";
const { lightgray, gray40, fontInterstate } = sassVars;
interface IOwnProps {
  headerTitle: string;
  onCloseBtnClicked: () => void;
  hideCloseIcon?: boolean;
  showHeaderToolTip?: boolean;
  headerToolTip?: string;
  showHeaderIcon?: boolean;
  headerIcon?: ReactChild;
}

const ModalHeader: React.FC<IOwnProps> = ({
  headerTitle,
  onCloseBtnClicked,
  hideCloseIcon,
  showHeaderToolTip = false,
  headerToolTip = "",
  showHeaderIcon,
  headerIcon,
}: IOwnProps) => {
  return (
    <StyledModalHeader data-testid="modal-header">
      <div className="se-model-header">
        {headerTitle}
        {showHeaderIcon && (
          <span onClick={onCloseBtnClicked}>{headerIcon}</span>
        )}
        {!hideCloseIcon && <></>}
      </div>
    </StyledModalHeader>
  );
};

const StyledModalHeader = styled.div`
  position: relative;
  padding: 15px;
  text-align: center;
  letter-spacing: -0.24px;
  border-bottom: 1px solid ${gray40};
  .close-modal-icon:hover {
    cursor: pointer;
  }
  .overflow-wrapper {
    margin-left: 5px;
  }
  .close-modal-icon {
    position: absolute;
    right: 20px;
  }

  .ul-list-style {
    list-style-type: none;
  }
  .se-model-header {
    font-family: ${fontInterstate};
    font-size: 16px;
    color: ${lightgray};
    text-align: left;
    & .se-close {
      float: right;
      cursor: pointer;
    }
    .threat-se-close {
      margin-top: 0.3rem;
    }
  }
  .motif-h4 {
    font-family: ${fontInterstate};
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: ${lightgray} !important;
  }
`;

export default ModalHeader;
