export enum RESPONSE_TYPE {
  ANSWER = "answer",
  TABLE = "table",
  ERROR = "error",
}

export type Iauthor = {
  name: string;
  profile: string;
};

export interface IContent extends IRelevantFiles {}

export type IQueryContent = {
  Content?: IContent[];
  ResponseType?: RESPONSE_TYPE;
  Sources?: string[];
  summary: string;
  downloadUrl?: string;
  sqlQuery?: string;
  showTable?: boolean;
  relevantFiles: Partial<IRelevantFiles>[];
};

export type IQuery = {
  [key: string]: IQueryContent;
};
export enum RESPONSE_BY {
  USER = "user",
  OPENAI = "LLMResponse",
  STRATEGY_EDGE = "SEResponse",
}

export interface User {
  summary: string;
}

export interface IAppConfig {
  baseApiUrl: string;
  activeDirectory: IActiveDirectoryConfig;
  azureMapsClientId: string;
}

export interface IActiveDirectoryConfig {
  tenant: string;
  clientId: string;
}
export interface IRelevantFiles {
  file_name: string;
  page_number: string[];
  authors: Iauthor[];
  sector?: string[];
  geography?: string[];
  contentAge:string
}
export interface IAppConfig {
  baseApiUrl: string;
  activeDirectory: IActiveDirectoryConfig;
  azureMapsClientId: string;
}

export interface IActiveDirectoryConfig {
  tenant: string;
  clientId: string;
}
export interface IRelevantFiles {
  file_name: string;
  page_number: string[];
  authors: Iauthor[];
}
