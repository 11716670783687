import {
  createStore,
  compose,
  AnyAction,
  StoreEnhancer,
  applyMiddleware,
} from "redux";
import { install, StoreCreator, combineReducers } from "redux-loop";

import {
  IChat,
  chatReducer,
  initialState as openAiInitialState,
} from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { rootSagas } from "./saga";
import {
  IUserProfileState,
  userProfileReducer,
  initialState as userProfileInitialState,
} from "./reducer/userProfileReducer";
import { PRODUCTION } from "../app.constant";

const sagaMiddleware = createSagaMiddleware();

export type IStore = {
  openAi: IChat;
  userProfile: IUserProfileState;
};

export const initialState: IStore = {
  openAi: openAiInitialState,
  userProfile: userProfileInitialState,
};

const reducers = combineReducers({
  openAi: chatReducer,
  userProfile: userProfileReducer,
});

export const enhancedCreateStore = createStore as StoreCreator;

export let enhancer: StoreEnhancer<any, AnyAction> = compose(
  install<IStore>(),
  applyMiddleware(...[sagaMiddleware])
);

if (process.env.NODE_ENV !== PRODUCTION) {
  // @ts-ignore
  if (typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION__) {
    const composeEnhancers = composeWithDevTools({
      trace: true,
      traceLimit: 25,
    });
    // @ts-ignore
    enhancer = compose(
      install<IStore>(),
      // @ts-ignore
      composeEnhancers(applyMiddleware(...[sagaMiddleware]))
    );
  }
}
const store = enhancedCreateStore(reducers, initialState, enhancer);

// const store = createStore(
//   reducers,
//   composeWithDevTools(applyMiddleware(...[sagaMiddleware]))
// );

sagaMiddleware.run(rootSagas);

export default store;
