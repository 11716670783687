import {
  CLEAR_CHAT_HISTORY_WATCHER,
  GET_CHAT_HISTORY,
  SET_CHAT_HISTORY,
  SET_DOWNLOAD_FILE_MESSAGE,
  TOGGLE_LOADER,
  TOOBLE_CLEAR_LOADER,
  SET_ERROR_MESSAGE,
  SET_CHAT_SUMMARY,
  SET_CLEAR_SUMMARY,
  SET_CHAT_TABLE,
  SET_WHOLE_CHAT_TABLE,
  SET_CHUNK_DATA_LOADING,
  CLEAR_CHAT_HISTORY,
  SET_RELEVANT_FILES,
  SET_USER_EMAIL,
} from "../types";
import LABELS from "../../configs/ui/en.json";
import { IRelevantFiles } from "../../configs/type/types";
import { AnyAction } from "redux";

export const setChatHistory = (payload: any) => {
  return {
    type: SET_CHAT_HISTORY,
    payload,
  };
};
export const setChatSummary = (payload: any) => {
  return {
    type: SET_CHAT_SUMMARY,
    payload,
  };
};
export const setClearSummary = () => {
  return {
    type: SET_CLEAR_SUMMARY,
  };
};
export const getChatHistory = () => {
  return {
    type: GET_CHAT_HISTORY,
  };
};
export const getChatTable = (payload: any) => {
  return {
    type: SET_CHAT_TABLE,
    payload,
  };
};
export const setWhoelChatTable = (payload: any) => {
  return {
    type: SET_WHOLE_CHAT_TABLE,
    payload,
  };
};
export const clearChatHistoryWacther = () => {
  return {
    type: CLEAR_CHAT_HISTORY_WATCHER,
  };
};
export const clearChatHistory = () => {
  return {
    type: CLEAR_CHAT_HISTORY,
  };
};

export const toggleLoader = (payload: any) => {
  return {
    type: TOGGLE_LOADER,
    payload,
  };
};

export const setClearLoader = (payload: any) => {
  return {
    type: TOOBLE_CLEAR_LOADER,
    payload,
  };
};

export const setDownloadFileMessage = (payload: any) => {
  return {
    type: SET_DOWNLOAD_FILE_MESSAGE,
    payload,
  };
};
export const setChunkLoading = (payload: boolean) => {
  return {
    type: SET_CHUNK_DATA_LOADING,
    payload,
  };
};
export const setRelevantFiles = (payload: Partial<IRelevantFiles>[]) => {
  return {
    type: SET_RELEVANT_FILES,
    payload,
  };
};

export const clearDownloadFileMessage = () => setDownloadFileMessage("");

export const setDownloadFileMessagePPT = () =>
  setDownloadFileMessage(LABELS.download_file_message.PPT);
export const setDownloadFileMessageWord = () =>
  setDownloadFileMessage(LABELS.download_file_message.WORD);
export const setDownloadFileMessageExcel = () =>
  setDownloadFileMessage(LABELS.download_file_message.EXCEL);

export const setErrorMessage = (payload: any) => {
  return {
    type: SET_ERROR_MESSAGE,
    payload,
  };
};
export const clearErrorMessage = () => setErrorMessage("");

export function setUSerEmail(email: string): AnyAction {
  return {
    type: SET_USER_EMAIL,
    email,
  };
}
