import React, { Fragment } from "react";
import styled from "styled-components";
import { ModalType } from "../../../modals/ModalType";
import LoadIndicator from "../../LoadingIndicator/LoadingIndicator";
import scssVar from "../../../styles/variables.module.scss";
const { gray40 } = scssVar;

interface IOwnProps {
  type: ModalType;
  actionBtnEnabled: boolean;
  actionBtnText: string;
  onActionBtnClicked?: () => void;
  onCloseBtnClicked: () => void;
  isLoading?: boolean;
  footerClass?: string;
}

const ModalFooter: React.FC<IOwnProps> = ({
  type,
  actionBtnEnabled,
  actionBtnText,
  onActionBtnClicked,
  onCloseBtnClicked,
  isLoading,
  footerClass,
}: IOwnProps) => {
  const typeToLower = ModalType[type].toLowerCase();
  const getTriggerBtnClass = () => {
    switch (type) {
      case ModalType.Submit:
        return "yellow-btn";
      case ModalType.Confirmation:
        return "yellow-btn";
      case ModalType.Privacy:
        return "yellow-btn";
      default:
        return "yellow-btn";
    }
  };

  return (
    <StyledModalFooter className={`${typeToLower}-modal ${footerClass} `}>
      {isLoading ? (
        <span className="loader">
          <LoadIndicator />
        </span>
      ) : (
        <Fragment>
          {type === ModalType.Error ? (
            <div>
              {isLoading ? (
                <LoadIndicator />
              ) : (
                <button
                  onClick={onCloseBtnClicked}
                  className="btn secondary-btn"
                >
                  {actionBtnText}
                </button>
              )}
            </div>
          ) : (
            <Fragment>
              {type === ModalType.Privacy ? (
                <>
                  <button
                    className="btn secondary-btn"
                    onClick={onCloseBtnClicked}
                  >
                    Close
                  </button>
                </>
              ) : type === ModalType.Confirmation ? (
                <>
                  <button
                    className={`btn ${getTriggerBtnClass()}`}
                    disabled={!actionBtnEnabled}
                    onClick={onActionBtnClicked}
                  >
                    {actionBtnText}
                  </button>
                  <button
                    className="btn secondary-btn"
                    onClick={onCloseBtnClicked}
                  >
                    Cancel
                  </button>
                </>
              ) : type === ModalType.Warning ? (
                <div>
                  {isLoading ? (
                    <LoadIndicator />
                  ) : (
                    <button
                      onClick={onCloseBtnClicked}
                      className="btn yellow-btn"
                    >
                      {actionBtnText}
                    </button>
                  )}
                </div>
              ) : type === ModalType.AdvancedSearch ? (
                <Fragment>
                  <button
                    className={`btn ${getTriggerBtnClass()}`}
                    disabled={!actionBtnEnabled}
                    onClick={onActionBtnClicked}
                  >
                    {actionBtnText}
                  </button>
                  <button
                    className="btn secondary-btn"
                    onClick={onCloseBtnClicked}
                  >
                    Close
                  </button>
                </Fragment>
              ) : (
                <>
                  <button
                    className="btn secondary-btn"
                    onClick={onCloseBtnClicked}
                  >
                    Cancel
                  </button>
                  <button
                    className={`btn ${getTriggerBtnClass()}`}
                    disabled={!actionBtnEnabled}
                    onClick={onActionBtnClicked}
                  >
                    {actionBtnText}
                  </button>
                </>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </StyledModalFooter>
  );
};

ModalFooter.displayName = "ModalFooter";

const StyledModalFooter = styled.div`
  border-top: 1px solid ${gray40};
  display: flex;
  padding: 10px;
  .loader {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .center-allign {
    text-align: center;
  }

  &.error-modal {
    justify-content: center;
  }

  &.submit-modal,
  &.confirmation-modal {
    justify-content: space-between;
  }
`;

export default ModalFooter;
