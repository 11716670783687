import React from "react";
import "./LinkButton.scss";

type LinkButtonProps = {
  onClick: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
};
const LinkButton: React.FC<LinkButtonProps> = ({
  onClick,
  children,
  disabled,
}) => {
  return (
    <button
      className="linkButton"
      onClick={() => onClick()}
      disabled={disabled}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      {children}
    </button>
  );
};

export default LinkButton;
