import React from "react";
import lodingchunks from "../../assets/Bars-1s-200px.svg";
import {
  IContent,
  IQuery,
  IQueryContent,
  RESPONSE_BY,
  RESPONSE_TYPE,
} from "../../configs/type/types";

import TI_IMG from "../../assets/TI.svg";
import OPENAI_IMG from "../../assets/openai.svg";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import LABELS from "../../configs/ui/en.json";
import LoadingIndicator from "../LoadingIndicator";

import "./ChatReply.scss";

type GetChatResponseProps = {
  chatData?: IQuery;
  loader?: boolean;
  isNew?: boolean;
  content?: IContent[];
  isSE: boolean;
  source?: string;
  messageLoading?: boolean;
  chunkLoading?: boolean;
  recentMessage?: boolean;
};

type OptionsButtonProps = {
  options: { onClick: any; label: string }[];
};

export type ParsedTableData = { headers: string[]; values: string[][] };

export const errorMessage = {
  SEError:
    "Oops! This data is not covered by Strategy Edge. Please refer to secondary research for more information on this.",
  OpenAIError: "Oops! No results found. Please try and refine your question.",
};

export const getSourcesList = (replyData: any, replySources: any) => {
  let result = replyData.split("\n");
  const uniqueSrc = [...replySources, ...result];
  return uniqueSrc.filter((string) => Boolean(string.trim()));
};

const GetChatResponse = ({
  chatData,
  loader,
  isNew,
  isSE,
  messageLoading,
  chunkLoading = false,
  recentMessage = false,
}: GetChatResponseProps) => {
  const [replyData, setReplyData] = React.useState<IQueryContent | null>();
  const isSideBarOpen = useSelector(
    (state: RootStateOrAny) => state.openAi.isSideBarOpen
  );

  const chatbotImageClass = isNew
    ? "chatreply__img-container"
    : "chatreply__img-container-dark";

  React.useEffect(() => {
    let data: IQueryContent | null = chatData
      ? chatData[RESPONSE_BY.STRATEGY_EDGE] || chatData[RESPONSE_BY.OPENAI]
      : null;

    setReplyData(data);
  }, [chatData]);

  const seTokenError = React.useCallback(() => {
    if (
      replyData?.summary?.includes(LABELS.TOKEN_ERR) ||
      replyData?.summary?.includes("https://aka.ms/oai/quotaincrease") ||
      replyData?.summary.includes("SQL_ERROR")
    ) {
      return true;
    } else {
      return false;
    }
  }, [replyData?.summary]);

  return (
    <div className={!isSideBarOpen ? "chatreply" : "chatreply sidebar-open"}>
      <div className={chatbotImageClass}>
        <img src={isSE ? TI_IMG : OPENAI_IMG} alt={"chatbot"} />
      </div>
      {!loader && (
        <>
          <div
            className={
              !isSideBarOpen
                ? "chatreply__resp-box"
                : "chatreply__resp-box sidebar-open"
            }
          >
            <div className={"chatreply__resp-box__resp"}>
              <span>
                <span dangerouslySetInnerHTML={{__html: 
                replyData?.ResponseType !== RESPONSE_TYPE.ERROR &&
                replyData?.summary
                  ? replyData.summary
                  : ""}
                }>
                  </span>
                {chunkLoading && recentMessage && (
                  <span
                    className="chunk-loader"
                    style={{ backgroundImage: `url(${lodingchunks})` }}
                  ></span>
                )}

                {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                  chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                  !seTokenError() &&
                  LABELS.chat_reply.SE_ERROR_MSG}
                {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                  chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                  seTokenError() &&
                  LABELS.chat_reply.SE_TOKEN_ERROR_MSG}
                {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                  chatData?.[RESPONSE_BY.OPENAI] &&
                  LABELS.chat_reply.OPENAI_ERROR_MSG}
              </span>
            </div>

            <div className="chatreply__source">Source: Transcripts</div>
          </div>
        </>
      )}
      {loader && !chunkLoading && (
        <div className={"chatreply__loader-container"}>
          <LoadingIndicator
            color="#FFF"
            parentWidth="13px"
            zeroPercentheight="38px"
            fifltyPercentheight="16px"
          />
        </div>
      )}
    </div>
  );
};
export default React.memo(GetChatResponse);
