import React from "react";
import "./OptionsButton.scss";

type OptionsButtonProps = {
  options: { onClick: any; label: string; display?: boolean }[];
};

const OptionsButton = ({ options }: OptionsButtonProps) => {
  const [openOptionsList, setOpenOptionsList] = React.useState(false);
  return (
    <button
      onClick={() => setOpenOptionsList((prev) => !prev)}
      className="options_wrapper"
    >
      <span className="options_wrapper__dots">...</span>
      {openOptionsList && (
        <div className="options_wrapper__list">
          {options?.map(
            ({
              onClick,
              label,
              display,
            }: {
              onClick: any;
              label: string;
              display?: boolean;
            }) =>
              display ? (
                <button key={label} onClick={onClick}>
                  {label}
                </button>
              ) : null
          )}
        </div>
      )}
    </button>
  );
};

export default OptionsButton;
