import React from "react";
import styled from "styled-components";
import varScss from "../../styles/variables.module.scss";
const { lightBlue, darkBlue } = varScss;

export const extractInitials = (name: string): string => {
  const initials: string = name
    .replace(/[(&/-]/g, " ")
    .replace(/[ ]{2,}/g, " ")
    .replace(/[^A-Za-z0-9 ]/g, "");
  const nameArray = initials.split(" ");

  if (!initials) return "";

  return nameArray.length > 1
    ? nameArray[0].charAt(0).toUpperCase() +
        nameArray[1].charAt(0).toLowerCase()
    : initials[0].toUpperCase() + initials[1].toLowerCase();
};

interface IAvatarInitialsProps {
  title: string;
  className?: string;
}

const AvatarInitials = ({
  title,
  ...restProps
}: IAvatarInitialsProps): JSX.Element => {
  return (
    <StyledAvatarInitials {...restProps}>
      <span className="center-wrapper center-wrapper-avatar">
        {extractInitials(title)}
      </span>
    </StyledAvatarInitials>
  );
};

const StyledAvatarInitials = styled.div`
  min-width: 39px;
  width: 39px;
  min-height: 39px;
  height: 39px;
  background-color: inherit;
  position: relative;

  &.company-color-code {
    background-color: ${lightBlue};
  }

  &.portfolio-color-code {
    background-color: ${darkBlue};
  }
  .center-wrapper-avatar {
    font-size: 20px;
    top: 1.2rem;
  }
`;

export default AvatarInitials;
