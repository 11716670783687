import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../modal/Modal";
import PrivacyModalContent from "./content/PrivacyModalContent";
import TermsModalContent from "./content/TermsModalContent";
import ClientDemoModalContent from "./content/ClientDemoModalContent";
import { content } from "./content/ClientDemoDisclaimer";
import { ModalType } from "../../../modals/ModalType";
import scssVar from "../../../styles/variables.module.scss";

interface IOwnProps {
  show: boolean;
  type: "privacy" | "terms" | "client" | null;
  onClose: () => void;
  headerIcon: React.ReactChild;
}

const PrivacyAndTermsModal: React.FC<IOwnProps> = ({
  show,
  type,
  onClose,
  headerIcon,
}: IOwnProps) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  let headerTitle;
  if (type === "privacy") {
    headerTitle = "Privacy Notice";
  } else if (type === "terms") {
    headerTitle = "Terms of Use";
  } else {
    headerTitle = "EY Embryonic Demo: Disclaimer";
  }
  return (
    <div className="se-model-main">
      <Modal
        isOpen={show}
        width={+scssVar.modalwidth}
        type={
          type === "privacy" || type === "terms"
            ? ModalType.Privacy
            : ModalType.Error
        }
        headerTitle={headerTitle}
        actionBtnText={
          type === "privacy" || type === "terms" ? "Accept" : "Close"
        }
        actionBtnEnabled
        onCloseBtnClicked={onClose}
        headerIcon={headerIcon}
        showHeaderIcon={true}
      >
        <StyledFooterModalContent className="se-modal-body gray-2 noto-sans">
          <Fragment>
            {ModalType.Privacy ? (
              <PrivacyModalContent />
            ) : (
              <TermsModalContent />
            )}
          </Fragment>
        </StyledFooterModalContent>
      </Modal>
    </div>
  );
};

export const StyledFooterModalContent = styled.div`
  padding: 50px;
  text-align: left;
  line-height: 1.375rem;

  h1,
  h4 {
    color: ${scssVar.alternategray};
  }

  h1 {
    margin: 0;
    padding-bottom: 1.25rem;
    font-weight: 500;
    font-size: 2.25rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 3.063rem;
  }

  h4 {
    font-weight: 500;
  }

  ol {
    list-style-type: upper-roman;
  }
  .privacy-modal .motif-h4 {
    font-family: ${scssVar.fontInterstate};
    font-size: 14px;
    line-height: 20px;
    color: ${scssVar.alternategray}!important;
  }
  .privacy-modal .motif-h6 {
    font-family: ${scssVar.fontInterstate};
    font-size: 12px;
    line-height: 20px;
    color: ${scssVar.alternategray}!important;
    font-weight: lighter;
  }
`;

export default React.memo(PrivacyAndTermsModal);
