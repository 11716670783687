import React, { useRef, useState } from "react";
import styled from "styled-components";
import sassVars from "../../styles/variables.module.scss";
import ProfileMenu from "../profileMenu/ProfileMenu";
import { useDetectOutsideClick } from "../../utils/commonHooks/useDetectOutsideClick";
import AvatarInitials from "../common/AvatarInitials";
import { closeIcon, HELP_URL } from "../../app.constant";
import LogoutModal from "../logOut/LogoutModal";
const { gray3A, fontInterstate, lightBlue } = sassVars;

interface IOwnProps {
  userData: {
    profileImage: string;
    displayName: string;
    sid?: string;
    shortName?: string;
  };
  profileMenuList?: Array<{ id: number; item: string; icon: string }>;
  disableHelpMenu: boolean;
}

const ProfileCard: React.FC<IOwnProps> = ({
  userData,
  profileMenuList = [],
  disableHelpMenu = false,
}: IOwnProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const handleProfileMenu = (e: React.MouseEvent) => {
    setIsActive(!isActive);
    e.stopPropagation();
  };
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const configureMenuList = disableHelpMenu
    ? profileMenuList.filter((x) => x.item !== "Help")
    : profileMenuList;
  const onClickMenuIcon = (e: any, item: string) => {
    switch (item) {
      case "Help":
        window.open(HELP_URL, "_blank");
        break;
      case "Contact us":
        window.location.href = "mailto:strategyedge@uk.ey.com";
        break;
      case "Logout": {
        setShowLogoutModal(true);
        break;
      }
    }
  };

  return (
    <ProfileStyled>
      <div className="se-header-icons-placeholder">
        <div className="se-row">
          <div className="se-col-xs-1">
            <div className="se-dropdown ">
              <div className="se-dropdown-trigger">
                <button onClick={handleProfileMenu} className="se-icon-button">
                  <div className="se-avatar se-avatar-size-small ">
                    {userData?.profileImage ? (
                      <img
                        className="image-round"
                        alt="Profile"
                        src={userData?.profileImage}
                      />
                    ) : (
                      <AvatarInitials title={userData?.displayName} />
                    )}
                  </div>
                </button>
              </div>
              <ProfileMenu
                dropdownRef={dropdownRef}
                isActive={isActive}
                disableHelpMenu={disableHelpMenu}
                profileMenuList={configureMenuList}
                userData={userData}
                onClickMenu={onClickMenuIcon}
              />
              <LogoutModal
                headerIcon={
                  <img
                    onClick={() => setShowLogoutModal(false)}
                    className="se-close"
                    src={closeIcon}
                    alt="close-icon"
                  />
                }
                show={showLogoutModal}
                onClose={() => setShowLogoutModal(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </ProfileStyled>
  );
};
export default ProfileCard;

const ProfileStyled = styled.div`
  .se-header-icons-placeholder {
    position: absolute;
    right: -0.1rem;
    top: 5px;
    display: flex;
    margin-left: 0.42857rem;
     & .se-row {
      height: 100%;
      flex-basis: 100%;
    }
    & .se-dropdown {
      position: relative;
      display: inline-block;
      & .top:before {
        content: "";
        border-bottom: 12px solid ${gray3A};
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        position: absolute;
        right: 26px;
        top: -12px;
      }

  
    & .se-dropdown-trigger {
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      border: 1px solid transparent;
      position: relative;
      z-index: 1;
      & button {
        margin-right: 0.42857rem;
        display: flex;
        align-items: center;
        width: 3.85714rem;
        justify-content: center;
      }
    }
    & .se-icon-button {
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      padding: 0;
    }
    & .se-avatar-size-small {
      height: 2.71429rem;
      width: 2.71429rem;
    }
    & .se-avatar {
      border-style: solid;
      border: 0.14286rem solid transparent;
      position: relative;
      border-radius: 50%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 2px;
      & .image-round {
        border-radius: 50%;
      }
     & .center-wrapper-avatar {
      font-size: 0.563rem;
      line-height: 22px;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      width: 1.75rem;
      height: 1.75rem;
      background: ${lightBlue};
      font-family: ${fontInterstate};
      font-size: 0.875rem;
      padding:4px 0px 0px 0px;
     }
       }
    @media screen and (min-width: 64em) {
      .se-row {
        margin-right: -12px;
        margin-left: -12px;
      }
    }
    @media screen and (min-width: 48em) {
      .se-row {
        margin-right: -12px;
        margin-left: -12px;
      }
    }
    @media screen and (min-width: 36em) {
      .se-row {
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }
`;
