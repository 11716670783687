import React from "react";
import "./SendMessage.scss";
import MessageEditMode from "../MessageEditMode/MessageEditMode";
import OptionsButton from "../OptionsButton/OptionsButton";
import { IQueryContent, RESPONSE_BY } from "../../configs/type/types";
import LABELS from "../../configs/ui/en.json";
import useExportToFile from "../../hooks/useExportToFile";
import { exportChatToPPT } from "../../utils/exportChatToPPT";
import { pptDataType } from "../../utils/exportChatToPPT";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  clearDownloadFileMessage,
  setDownloadFileMessage,
  setDownloadFileMessagePPT,
  setDownloadFileMessageWord,
} from "../../redux/action";

type SendMessageProps = {
  message: IQueryContent;
  isNew?: boolean;
  loader: boolean;
  dataExport: pptDataType;
  handleSendMessage: (ev: any) => void;
};

const SendMessage = ({
  message,
  isNew,
  loader,
  dataExport,
  handleSendMessage,
}: SendMessageProps) => {
  const dispatch = useDispatch();
  const { exportChatToPPT } = useExportToFile();
  const [showOptions, setShowOptions] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const USER_IMG = require("../../assets/avtar.png");
  const queryImageClass = isNew
    ? "openaiquery__img-container"
    : "openaiquery__img-container-dark";

  return (
    <div className={"openaiquery"}>
      <div className={queryImageClass}>
        <img style={{ height: 40, width: 40 }} src={USER_IMG} alt={"user"} />
      </div>
      {isEditMode ? (
        <MessageEditMode
          message={message.summary}
          handleSendMessage={handleSendMessage}
          setIsEditMode={setIsEditMode}
        />
      ) : (
        <div
          className={"openaiquery__query-box"}
          onMouseOver={() => {
            setShowOptions(true);
          }}
          onMouseLeave={() => {
            setShowOptions(false);
          }}
        >
          {message.summary}
          {showOptions && !loader && (
            <OptionsButton
              options={[
                {
                  label: LABELS.send_message.EXPORT_PPT_BTN,
                  onClick: async () => {
                    dispatch(setDownloadFileMessagePPT());
                    await exportChatToPPT(dataExport);
                    dispatch(clearDownloadFileMessage());
                  },
                  display: true,
                },
                {
                  label: LABELS.send_message.EDIT_BTN,
                  onClick: () => setIsEditMode(true),
                  display: true,
                },
              ]}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default SendMessage;
