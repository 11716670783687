import {
  IQuery,
  IQueryContent,
  IRelevantFiles,
  Iauthor,
  RESPONSE_BY,
  RESPONSE_TYPE,
} from "../configs/type/types";
import {
  BorderStyle,
  Document,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
} from "docx";
import USER_IMG from "../assets/avtar.png";
import TR_IMG from "../assets/TR.png";
import OPENAI_IMG from "../assets/openai.png";
import { downloadFilenameGenerator } from ".";
import LABELS from "../configs/ui/en.json";

const IMAGES = {
  [RESPONSE_BY.USER]: USER_IMG,
  [RESPONSE_BY.OPENAI]: OPENAI_IMG,
  [RESPONSE_BY.STRATEGY_EDGE]: TR_IMG,
};

type tableContentType = {
  file_name: string;
  page_number: string[];
  authors: Iauthor[];
};
const noBorder = {
  style: BorderStyle.NONE,
  size: 0,
  color: "ff0000",
};

const noBorders = {
  top: noBorder,
  bottom: noBorder,
  left: noBorder,
  right: noBorder,
};

const seTokenError = (replyData: string) => {
  if (
    replyData?.includes(LABELS.TOKEN_ERR) ||
    replyData?.includes("https://aka.ms/oai/quotaincrease") ||
    replyData?.includes("SQL_ERROR")
  ) {
    return true;
  } else {
    return false;
  }
};
const generateDocument = async (data: IQuery[]) => {
  const paragraphs: any = [];
  data.forEach((chat: IQuery) => {
    const key = Object.keys(chat)[0];
    const img = IMAGES[key as RESPONSE_BY];
    const values: IQueryContent = chat[key];
    let tableContent: tableContentType[] = [];
    if (
      values?.relevantFiles &&
      values?.relevantFiles.every((el) => Boolean(el) && typeof el === "object")
    ) {
      tableContent = values.relevantFiles.map((el) => {
        const obj: IRelevantFiles = {
          file_name: el.file_name ?? "",
          page_number: el.page_number || [],
          authors: el?.authors || [],
          sector: el?.sector || [],
          geography: el?.geography || [],
          contentAge: el?.contentAge || "",
        };
        return obj;
      });
    }

    if (values?.ResponseType === RESPONSE_TYPE.ERROR) {
      values.summary =
        key === RESPONSE_BY.STRATEGY_EDGE
          ? seTokenError(values.summary)
            ? LABELS.chat_reply.SE_TOKEN_ERROR_MSG
            : LABELS.chat_reply.SE_ERROR_MSG
          : LABELS.chat_reply.OPENAI_ERROR_MSG;
    }
    if (key === RESPONSE_BY.USER) {
      paragraphs.push(
        new TableRow({
          children: [
            new TableCell({
              borders: noBorders,
              margins: {
                right: 200,
              },
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: img,
                      transformation: {
                        width: 30,
                        height: 30,
                      },
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              borders: noBorders,
              children: [
                new Paragraph(values.summary?.replace(/\n\r+$/, "")),
                new Paragraph("\n"),
              ],
            }),
          ],
        })
      );
    }
    if (key === RESPONSE_BY.STRATEGY_EDGE) {
      paragraphs.push(
        new TableRow({
          children: [
            new TableCell({
              borders: noBorders,
              margins: {
                right: 200,
              },
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: img,
                      transformation: {
                        width: 30,
                        height: 30,
                      },
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              borders: noBorders,
              children: [
                ...values.summary
                  .replace(/\n+$/, "")
                  .split("\n")
                  .map((line) => new Paragraph(line)),
                new Paragraph("Source: Transcripts"),
                new Paragraph("\n"),
              ],
            }),
          ],
        })
      );
    }
  });
  const doc = new Document({
    styles: {
      default: {
        document: {
          run: {
            size: "11pt",
            font: "Calibri",
          },
        },
      },
      paragraphStyles: [
        {
          id: "tableHeader",
          run: {
            bold: true,
          },
        },
        {
          id: "summary",
          quickFormat: true,
        },
      ],
    },
    sections: [
      {
        children: [
          new Table({
            rows: paragraphs,
          }),
        ],
      },
    ],
  });
  return doc;
};

const exportChatToWord = async (data: IQuery[]) => {
  const clonedData = structuredClone(data);
  const doc = await generateDocument(clonedData);
  const blob = await Packer.toBlob(doc);
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = downloadFilenameGenerator("ALL", "docx");
  link.click();
};

export default exportChatToWord;
