import { useState, useEffect } from "react";
/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export const useDetectOutsideClick = (
  el: React.RefObject<HTMLDivElement>,
  initialState: any
) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e: any) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      document.addEventListener("click", onClick);
    }
  }, [isActive, el]);

  return [isActive, setIsActive];
};
