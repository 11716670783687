import { RESPONSE_BY } from "../configs/type/types";
import { Guid } from "guid-typescript";
import { authProvider } from "../msalConfig";

export const charLimit = 1000;

export const sanitizeHeaderText = (header: string) => {
  return header.replace("company_", "").replace("_", " ");
};

export const toPascalCase = (str: string) => {
  return str.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};
export const checkIsJson = (str: string) => {
  const rx =
    /^[\],:{}\s]*$|\/(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})*$|"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?$/;
  const value = rx.test(str);
  return value;
};
export const downloadFilenameGenerator = (
  from: RESPONSE_BY | "ALL",
  extension: "ppt" | "docx" | "csv"
) => {
  const guid = Guid.create();
  const source =
    extension === "docx"
      ? "TI GPT"
      : from === RESPONSE_BY.OPENAI
        ? "OpenAI"
        : from === RESPONSE_BY.STRATEGY_EDGE
          ? "TI GPT"
          : "User";

  const fileName = `${source}-${
    extension === "docx" ? "conversations" : "chat reply"
  }-${guid}.${extension}`;
  return fileName;
};

export const GenrateRandomAuthor = () => {
  const random = Math.floor(Math.random() * 10);
  const Anshul = [
    {
      name: "Anshul Gupta",
      profile:
        "https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Canshul.gupta%40uk.ey.com",
    },
  ];
  const Priyam = [
    {
      name: "Priyam Lahiri",
      profile:
        "https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Cpriyam.lahiri%40uk.ey.com",
    },
  ];

  const multipleAuthors = [
    {
      name: "Priyam Lahiri",
      profile:
        "https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Cpriyam.lahiri%40uk.ey.com",
    },
    {
      name: "Anshul Gupta",
      profile:
        "https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Canshul.gupta%40uk.ey.com",
    },
  ];
  if (random % 3 === 0) {
    return Anshul;
  }
  if (random % 3 === 1) {
    return Priyam;
  } else {
    return multipleAuthors;
  }
};

export const checkOnySpecialCharacter = (str: string) => {
  const rx = /^[^a-zA-Z]+$/;
  var res = rx.test(str);
  return res;
};

export const checkOnlySpaces = (str: string) => {
  const rx = /^\s+$/;
  var res = rx.test(str);
  return res;
};
export const getAuthorizationToken = async () => {
  const res = await authProvider?.getIdToken();
  return res.idToken.rawIdToken;
};
