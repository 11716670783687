import * as fetchIntercept from "fetch-intercept";
import { getAuthorizationToken } from "../utils";
import { getCurrentSessionId, logoutandClearSession } from "./session.service";
import { Guid } from "guid-typescript";
import { FetchInterceptorResponse } from "fetch-intercept";

const sessionId = getCurrentSessionId();

export const initFetch = () => {
  return fetchIntercept.register({
    request: async function (url: string, config: any) {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        "session-id": sessionId || "",
        Authorization: `Bearer ${sessionId}`, 
        "Request-Id": `${Guid.create().toString()}`,
      };
      config.headers["Request-Id"] = Guid.create();
      config.headers["X-Correlation-Id"] = Guid.create();
      config.headers["X-Window-Id"] = sessionId;
      return [url, config];
    },

    requestError: function (error: any) {
      return Promise.reject(error);
    },

    response: function (response: FetchInterceptorResponse) {
      if (response.status === 401) {
        logoutandClearSession();
      }
      return response;
    },

    responseError: function (error: any) {
      return Promise.reject(error);
    },
  });
};
