import styled from "styled-components";
import scssVar from "../../styles/variables.module.scss";
import AppLogo from "./AppLogo";
import ProfileCard from "../ProfileCard/ProfileCard";
import { useSelector } from "react-redux";
import { IStore } from "../../redux/store";
import { profileMenuList } from "../../app.constant";

type ICustomHeader = {
  handleNavigationHome?: () => void;
};
const { darkwhite, white, gray50, gray15, alternategray, fontInterstate } =
  scssVar;
export const humIcon = `data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z' fill='%23C4C4CD'/%3E%3C/svg%3E%0A`;

const CustomHeader = ({ handleNavigationHome }: ICustomHeader) => {
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  return (
    <StyleHeader>
      <header className="se-header se-header-fixed se-header-has-search">
        <AppLogo handleNavigationHome={handleNavigationHome} />
        <ProfileCard
      userData={userData}
      profileMenuList={profileMenuList}
      disableHelpMenu={userData?.isExternal}
        />
      </header>
    </StyleHeader>
  );
};
export default CustomHeader;
const StyleHeader = styled.div`
  position: relative;
  z-index: 1111111111;
  .se-header.se-header-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  .se-header {
    display: block;
    background: ${gray50};
    color: ${white};
    padding: 0 0 0 0.625rem;
    z-index: 100;
    min-height: 3.85714rem;
  }
  header {
    .vl {
      border-left: 3px solid #d1ead1;
      height: 22px;
      padding: 8px;
      position: relative;
      top: 23px;
    }
    .image-round {
      height: 2.51429rem;
      width: 2.51429rem;
      margin-right: 37px;
    }
    .logo-text {
      font-size: 18px;
      position: relative;
      left: 10px;
      top: 2px;
    }
    .se-header .se-header-app-name {
      position: relative;
      left: 5px;
      font-family: ${fontInterstate};
      font-size: 18px;
      line-height: 21px;
      color: ${darkwhite};
      height: auto;
      font-size: 18px;
      bottom: 9px;
    }
    .se-header .se-search-input-wrapper .se-input-component .se-input {
      background: ${gray15};
      height: 3.20714rem !important;
      position: relative;
      top: 6px;
    }
    .se-header.se-header-fixed {
      height: 56px;
    }
    .se-header-logo {
      left: 12px;
      bottom: 5px;
    }
    .hamburgur .se-icon {
      color: ${alternategray} !important;
    }
    .hamburgur {
      background-color: transparent !important ;
      background-color: transparent;
      position: relative;
      left: 5px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      top: 5px;
      disaplay: none;
    }
    .hamburgur:hover {
      background-color: transparent;
    }
    .se-icon-button {
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      padding: 0;
      transition: box-shadow 150ms;
      display: none;
      color: #1a1a24;
      & .se-icon {
        background: transparent;
        border: none;
        & img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .se-header .se-row {
      height: 100%;
      flex-basis: 100%;
    }
    @media screen and (min-width: 64em) {
      .se-header-company-container {
        flex: 0 0 20%;
        max-width: 100%;
        box-sizing: border-box;
        box-flex: 0;
      }
    }
    @media screen and (min-width: 0) {
      .se-between-xs {
        justify-content: flex-start;
      }
    }
    @media screen and (min-width: 48em) {
      .se-header .se-header-search {
        width: 64.85714rem;
      }
    }
    .se-header-search-field {
      border-color: black;
    }
    .se-header-icons-placeholder {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
`;
