import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import scssVar from "../../styles/variables.module.scss";
import labels from "../../configs/ui/en.json";
import TRANS_KEY from "../../assets/transcriptkey.svg";
type ICustomHeader = {
  hideShowOptions?: boolean;
  checkedgeography?: string[];
  checkedsector?: string[];
  handleNavigationHome?: () => void;
  clearContentAge?: () => void;
  clsoeOtherDropdowns?: () => void;
};

const { darkwhite, white, gray50, gray15, alternategray, fontInterstate } =
  scssVar;

const InsightHeader = ({ length }: any) => {
  return (
    <StyleHeader>
      <header className="insight-header insight-header-fixed insight-header-has-search">
        <div
          className={length <= 0 ? "insight-header-bg" : "insight-header-bg-wc"}
        >
          <div className="logo-text">{labels.TITLE}</div>
          {length <= 0 && <p className="logo-descr">{labels.LOGO_DESC}</p>}
        </div>

        <div
          className={
            length > 0
              ? "unstructure__header-container-with-chat"
              : "unstructure__header-container"
          }
        >
          <div>
            <img src={TRANS_KEY} />
          </div>
          <div className="thin-white-line">
            What would you like to know today?
          </div>
        </div>
      </header>
    </StyleHeader>
  );
};
export default InsightHeader;
const StyleHeader = styled.div`
  .dropdown-parent {
    position: relative;
  }
  .insight-header.insight-header-fixed {
    width: 100vw;
    padding-top: 59px;
    // position: fixed;
  }
  .insight-header {
    display: block;
    z-index: 100;
    min-height: 3.85714rem;
  }
  .insight-header-bg {
    background: var(--Green-900, #0a3616bd);
    padding: 36px 60px;
  }
  .insight-header-bg-wc {
    background: var(--Green-900, #0a3616bd);
    padding: 32px 60px 32px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header {
    .vl {
      border-left: 3px solid #d1ead1;
      height: 22px;
      padding: 8px;
      position: relative;
      top: 23px;
    }
    .image-round {
      height: 2.51429rem;
      width: 2.51429rem;
      margin-right: 37px;
    }
    .logo-text {
      color: var(--primary-ey-yellow-ffe-600, #ffe600);
      font-family: EYInterstate;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    .logo-descr {
      color: var(--primary-grey-100-f-6-f-6-fa, #f6f6fa);
      font-family: EYInterstate;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      margin: 0;
    }
    .insight-header .insight-header-app-name {
      position: relative;
      left: 5px;
      font-family: ${fontInterstate};
      font-size: 18px;
      line-height: 21px;
      color: ${darkwhite};
      height: auto;
      font-size: 18px;
      bottom: 9px;
    }
    .insight-header .se-search-input-wrapper .se-input-component .se-input {
      background: ${gray15};
      height: 3.20714rem !important;
      position: relative;
      top: 6px;
    }
    .insight-header.insight-header-fixed {
      height: 56px;
    }
    .insight-header-logo {
      left: 12px;
      bottom: 5px;
    }

    .hamburgur .se-icon {
      color: ${alternategray} !important;
    }
    .hamburgur {
      background-color: transparent !important ;
      background-color: transparent;
      position: relative;
      left: 5px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      top: 5px;
      disaplay: none;
    }
    .hamburgur:hover {
      background-color: transparent;
    }
    .se-icon-button {
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      padding: 0;
      transition: box-shadow 150ms;
      display: none;
      color: #1a1a24;
      & .se-icon {
        background: transparent;
        border: none;
        & img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .insight-header .se-row {
      height: 100%;
      flex-basis: 100%;
    }
    .sticky-filter {
      max-height: 200px;
      overflow: auto;
      padding: 0px 0px 0px 16px;
    }
    .btn-filter {
      width: 44px;
      border: 1px solid ${scssVar.gray40};
      height: 44px;
      position: relative;
      cursor: pointer;
      .filter-icon {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
      }
    }
    .btn-filter-text {
      width: 100px;
      border: 1px solid ${scssVar.gray40};
      height: 44px;
      position: relative;
      cursor: pointer;
      .filter-text {
        position: absolute;
        top: 0.5rem;
        left: 0.9rem;
      }
    }
    @media screen and (min-width: 64em) {
      .insight-header-company-container {
        flex: 0 0 20%;
        max-width: 100%;
        box-sizing: border-box;
        box-flex: 0;
      }
    }
    @media screen and (min-width: 0) {
      .se-between-xs {
        justify-content: flex-start;
      }
    }
    @media screen and (min-width: 48em) {
      .insight-header .insight-header-search {
        width: 64.85714rem;
      }
    }
    .insight-header-search-field {
      border-color: black;
    }
    .insight-header-icons-placeholder {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
`;
