import { getCurrentSessionId } from "../../services/session.service";
import { getAuthorizationToken } from "../../utils";
import apiRoutes from "./apis";

const ClearHistoryService = async () => {
  const sessionId = getCurrentSessionId();
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "session-id": sessionId || "",
        "user-id": "Ey-User",
        Authorization: `Bearer ${sessionId}`, //${await getAuthorizationToken()}
      },
    };
    return fetch(apiRoutes.CLEAR_CHAT, requestOptions);
  } catch (err) {
    console.error("Clear chat eror", err);
  }
};

export default ClearHistoryService;
