import React from "react";
import styled from "styled-components";
import LOGO from "../../assets/Logo.svg";

type IAppLog = {
  handleNavigationHome?: () => void;
};
const AppLogo = ({ handleNavigationHome }: IAppLog) => {
  return (
    <StyleHeader className="logo-button">
      <div className="logo-container" onClick={handleNavigationHome}>
        <span>
          <img src={LOGO} className="logo-cls" />
        </span>
        <span className="name-cls">{"Transcripts Insights"}</span>
      </div>
    </StyleHeader>
  );
};
export default AppLogo;
const StyleHeader = styled.div`
  margin-left: 22px;
  position: relative;
  top: 3px;

  .logo-cls {
    margin-right: 12px;
    width: 1.5rem;
    height: 1.5rem;
    background: #2e2e38;
    border-radius: 2px;
  }
  .line-cls {
    margin-right: 8px;
  }
  .name-cls {
    position: relative;
    color: var(--primary-white, #fff);
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: EYInterstate;
    font-size: 1.25rem;
    font-weight: 400;
  }
  .logo-container {
    display: flex;
    align-items: end;
  }
`;
